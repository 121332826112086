import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import getAvailableUnits from '../../../../store/LearningMap/helpers/getAvaiblabeUnits'
import { initStore } from '../../../../store/configureStore'
import { fetchLearningMap } from '../../../../services/learningMapService'
import { updateLearningMap } from '../../../../store/LearningMap/LearningMapSlice'
import { hideLoading, showLoading } from '../../../../store/Loading/Loading'
import {
  updateCourseInfo,
  getWhoAmI
} from '../../../../services/courseInfoService'
import {
  getFamilyUserData,
  getFamilyMembers,
  setAloneUserData
} from '../../../../services/userTypeService'
import { getOrganization } from '../../../../services/loginService'
import {
  isSubscriptionHost,
  getLicenseInformation
} from '../../../../services/licenseService'
import { isDeviceSupported } from '../../../../util/mobile'
import { sendMagicLinkEmail } from '../../../../services/userManagementService'

export default function useMetaberryWithStore() {
  const history = useHistory()
  const dispatch = useDispatch()

  const storeReady = useSelector(
    (state) => state.configuration.wasStoreInitialized
  )

  const availableUnits = useSelector(getAvailableUnits)
  const isBlueberryFamily = window?.organizationData?.isBlueberryFamily

  async function init() {
    // ocLog('init')
    dispatch(showLoading())

    if (!isDeviceSupported()) {
      if (!isBlueberryFamily) {
        history.replace({
          pathname: '/download-app'
        })
      } else {
        sendMagicLinkEmail()
        history.replace({
          pathname: '/unsupported'
        })
      }

      return
    }

    if (await isLicenseExpired()) {
      return
    }

    const whoAmI = await getWhoAmI(true)

    // Si el usuario es familia
    if (whoAmI.role_guid === 'R03') {
      // Se comprueba que hay un miembro DE SU familia inicializado
      const familyMembers = await getFamilyMembers()
      const currentMemberData = getFamilyUserData()
      let foundMember = []

      if (currentMemberData) {
        foundMember = familyMembers.filter((member) => {
          return member.guid === currentMemberData.guid
        })
      }

      // Sino se solicita elegirlo
      if (foundMember.length === 0) {
        history.push('/select-profile')
        return
      }
    } else {
      const aloneData = {
        guid: whoAmI.guid,
        roleGuid: whoAmI.role_guid,
        roleName: whoAmI.role_name,
        name: whoAmI.name,
        lastname: whoAmI.lastname
      }

      setAloneUserData(aloneData)
    }

    initStore().then(() => {
      dispatch(hideLoading())
    })
  }

  async function update() {
    // ocLog('update')
    dispatch(showLoading())

    if (!isDeviceSupported()) {
      sendMagicLinkEmail()
      history.replace({
        pathname: '/unsupported'
      })

      return
    }

    if (await isLicenseExpired()) {
      return
    }

    updateCourseInfo()
      .then(() => fetchLearningMap())
      .then((data) => {
        dispatch(updateLearningMap(data))
        dispatch(hideLoading())
      })
  }

  useEffect(() => {
    !storeReady ? init() : update()
  }, [])

  async function isLicenseExpired() {
    let isExpired = false
    if (isSubscriptionHost()) {
      const organizationData = await getOrganization()
      const isBlueberryFamily =
        organizationData?.tenant?.isBlueberryFamily === 1

      if (isBlueberryFamily) {
        const licenseInformation = await getLicenseInformation()
        const leftDays = parseInt(licenseInformation.leftDays)
        isExpired = leftDays <= 0

        if (isExpired) {
          history.replace({
            pathname: '/pricing',
            state: licenseInformation
          })
        }
      }
    }

    return isExpired
  }

  return {
    availableUnits
  }
}
