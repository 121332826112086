import React, { useEffect, useState } from 'react'
import { ScaffoldingMobile, observer } from 'react-lemonade-editor'
import FeedbackIndicator from '../../atoms/FeedbackIndicator/FeedbackIndicator'
import { BREAKPOINTS } from '../../../constants/uiConstants'
import './scaffoldMobileContainer.scss'
import useScaffoldMobileContainer from './useScaffoldMobileContainer'
import { ACTIVITY_TYPES } from '../../../constants/ActivityConstants'

function ScaffoldMobileContainer({
  currentExercise,
  checkAnswer,
  hasCheckExerciseAPiResponded,
  activityWidth,
  autoFocus,
  disabled,
  onActivityEvent,
  feedbackIndicatorObject,
  pathAssetsSchool,
  // Para FeedbackBox
  feedbackBoxMessage,
  exerciseGuid,
  requiresAudioAssistance,
  getTextAudio,
  mathRenderEngine,
  // Para respuestas por defecto (dummy)
  isDummyAnswer
}) {
  const [footerWidth, setFooterWidth] = useState(window?.innerWidth || 0)
  const [widthClass, setWidthClass] = useState('')
  const [activityTypeClass, setActivityTypeClass] = useState('')

  const {
    getScaffoldStore,
    getQuestionStore,
    handleUserChange,
    renderFeedbackBox,
    canShowFeedbackIndicator,
    customButtons,
    scaffoldStore,
    activityType
  } = useScaffoldMobileContainer({
    checkAnswer,
    onActivityEvent,
    isDummyAnswer,
    feedbackBoxMessage,
    hasCheckExerciseAPiResponded,
    feedbackIndicatorObject,
    exerciseGuid,
    requiresAudioAssistance,
    getTextAudio,
    mathRenderEngine,
    pathAssetsSchool,
    footerWidth
  })

  useEffect(() => {
    setFooterWidth(window.innerWidth)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    let _widthClass = ''

    if (activityWidth < BREAKPOINTS.desktop) {
      if (activityWidth < BREAKPOINTS.tablet) {
        _widthClass = ' blueberry-scaffold-mobile-width_phone-size'
      } else {
        _widthClass = ' blueberry-scaffold-mobile-width_tablet-size'
      }
    }

    setWidthClass(_widthClass)
  }, [activityWidth])

  useEffect(() => {
    if (activityType === ACTIVITY_TYPES.PREVIEW) {
      setActivityTypeClass(' scaffoldin-mobile-preview')
    } else {
      setActivityTypeClass('')
    }
  }, [activityType])

  const handleResize = () => {
    setFooterWidth(window.innerWidth)
  }

  return (
    <div
      className={
        'blueberry-scaffold-mobile-container' + widthClass + activityTypeClass
      }
    >
      <ScaffoldingMobile
        data={currentExercise}
        onScaffoldStart={getScaffoldStore}
        onQuestionStart={getQuestionStore}
        changed={handleUserChange}
        autoFocus={autoFocus}
        disabled={disabled}
        customButtons={customButtons}
        mainQuestionSlot={
          <>
            {footerWidth < BREAKPOINTS.tablet &&
              scaffoldStore?.currentQuestionIndex === 0 &&
              renderFeedbackBox(0)}
          </>
        }
        scaffoldQuestionSlot={
          <>
            {footerWidth < BREAKPOINTS.tablet &&
              scaffoldStore?.currentQuestionIndex > 0 &&
              renderFeedbackBox(scaffoldStore?.currentQuestionIndex)}
          </>
        }
      />

      {canShowFeedbackIndicator() && (
        <FeedbackIndicator
          message={feedbackIndicatorObject.message}
          type={feedbackIndicatorObject.type}
          variant={feedbackIndicatorObject.variant}
          pathAssetsSchool={pathAssetsSchool}
        />
      )}
    </div>
  )
}

export default observer(ScaffoldMobileContainer)
