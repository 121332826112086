import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ACTIVITY_TYPES } from '../constants/ActivityConstants'
import {
  FEEDBACK_TOTAL_VARIANTS,
  FEEDBACK_TYPE
} from '../constants/FeedbackConstants'
import { useActivityContext } from '../context/ActivityContext/useActivityContext'
import { activityStorage } from '../utils/activityStorage'
import { setLemonadeDefaultConfig } from '../utils/setLemonadeConfig'
import { getConfig } from 'react-lemonade-editor'

export function useActivityManager({
  onActivityEvent,
  studentResponse,
  finishedTimeQuestion,
  activityType,
  showSolutionQuestion,
  showSolutionQuestionQuizPreview,
  mathRenderEngine,
  hasCheckExerciseAPiResponded
}) {
  const { t } = useTranslation()

  const {
    activity,
    activityStore,
    isScaffold,
    initializeActivity,
    setIsCheckButtonEnabled,
    setIsAnswerCorrect,
    scaffoldCurrentQuestion,
    scaffoldNext: scaffoldInnerNext,
    setIsScaffoldComplete,
    setIsDisabledActivity,
    setIsSolutionShowed
  } = useActivityContext()

  const [feedbackBoxMessage, setFeedbackBoxMessage] = useState('')
  const [feedbackIndicatorObject, setFeedbackIndicatorObject] = useState({
    message: '',
    variant: 0,
    type: FEEDBACK_TYPE.CORRECT
  })
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false)

  const [isDummyAnswer, setIsDummyAnswer] = useState(false)
  const [isResponseSend, setIsResponseSend] = useState(false)
  const isAnsweredOnTime = useRef(false)
  const [feedbackTitle, setFeedbackTitle] = useState('')
  const [feedbackSubtitle, setFeedbackSubtitle] = useState('')
  const [feedbackIcon, setFeedbackIcon] = useState(null)

  const [isScaffoldMobileVersion, setIsScaffoldMobileVersion] = useState(false)

  useEffect(() => {
    setLemonadeDefaultConfig(mathRenderEngine)
  }, [])

  useEffect(() => {
    if (
      activityType === ACTIVITY_TYPES.PRACTICE ||
      activityType === ACTIVITY_TYPES.PREVIEW
    ) {
      if (activity.type === 'scaffold') {
        getConfig().showAutoFeedback = false
        setIsScaffoldMobileVersion(true)
      } else {
        getConfig().showAutoFeedback = true
        setIsScaffoldMobileVersion(false)
      }
    }
  }, [activity])

  const checkMainAnswer = (forcedResult) => {
    let isUserCorrect = null
    let userResponse = null

    // Respondida de manera normal
    if (forcedResult === undefined) {
      if (
        (activityType === ACTIVITY_TYPES.QUIZ && isAnsweredOnTime.current) ||
        activityType !== ACTIVITY_TYPES.QUIZ
      ) {
        activityStore.checkAnswers()
        isUserCorrect = activityStore.getResults() === 1 ? 1 : 0
        userResponse = activityStore.getUserResponse()
      }

      if (activityType === ACTIVITY_TYPES.QUIZ && !isAnsweredOnTime.current) {
        activityStore.checkAnswers()
        isUserCorrect = 0
      }
    } else {
      // Repondida con botones para pruebas
      isUserCorrect = forcedResult
      userResponse =
        forcedResult === true ? activityStore.validation.value : ['']
      activityStore.setEvaluated(true)
    }

    return { isUserCorrect, userResponse }
  }

  const processMainCheck = (forcedResult) => {
    const mainCheck = checkMainAnswer(forcedResult)
    const isUserCorrect = mainCheck.isUserCorrect
    const userResponse = mainCheck.userResponse

    if (isScaffold) {
      setIsScaffoldComplete(isUserCorrect || forcedResult === false)

      if (!isUserCorrect) {
        scaffoldNext()
      }

      // Scaffold se fuerza al inicio con showAutoFeedback=false, aquí se resetea
      if (
        activityType === ACTIVITY_TYPES.PRACTICE ||
        activityType === ACTIVITY_TYPES.PREVIEW
      ) {
        getConfig().showAutoFeedback = true
      }
    }

    setIsAnswerCorrect(isUserCorrect)

    if (
      (activityType === ACTIVITY_TYPES.PRACTICE ||
        activityType === ACTIVITY_TYPES.QUIZ) &&
      (!isScaffold || scaffoldCurrentQuestion.isMain)
    ) {
      onActivityEvent({
        name: 'Check Answer',
        properties: {
          isUserCorrect: isUserCorrect,
          userResponse: userResponse,
          isScaffold: isScaffold,
          isMain: !isScaffold || scaffoldCurrentQuestion.isMain
        }
      })
    }

    return isUserCorrect
  }

  const handlePartialMatchFeedback = (lowerFeedbackType) => {
    // Si es "multiple choice - multiple response" el mensaje debe aclarar si marcaron las necesarias
    // "getPartialMatch" genera error, podría ser algo de lemonade
    try {
      // El usuario tenía que seleccionar varias opciones correctas pero no seleccionó todas
      const questionData = !isScaffold
        ? activity
        : activity.questions[scaffoldCurrentQuestion.index]
      const template = (questionData?.metadata?.name || '').toLowerCase()
      const isMultipleResponse =
        template.indexOf('multiple choice') !== -1 &&
        template.indexOf('multiple response') !== -1

      if (isMultipleResponse) {
        const partialMatch = activityStore.getPartialMatch()
        if (partialMatch > 0 && partialMatch < 1) {
          setFeedbackIndicatorObject({
            message: t('activity_partial_match_feedback_subtitle'),
            variant: 2,
            type: lowerFeedbackType
          })
        }
      }
    } catch (e) {
      console.error('Error at getPartialMatch:', e)
    }
  }

  const setAnswerFeedback = (
    isUserCorrect,
    lowerFeedbackType,
    randomFeedback,
    defaultFeedback
  ) => {
    setFeedbackIndicatorObject({
      message: randomFeedback.title,
      variant: randomFeedback.variant,
      type: lowerFeedbackType
    })

    setFeedbackBoxMessage(defaultFeedback || randomFeedback.message)

    if (!isUserCorrect) {
      handlePartialMatchFeedback(lowerFeedbackType)
    }

    if (activityType === ACTIVITY_TYPES.QUIZ) {
      if (!isAnsweredOnTime.current) {
        setFeedbackIndicatorObject({
          message: `${t(
            'app_practice_incorrect_feedback_title_no_answer'
          )}  ${t('app_practice_incorrect_feedback_subtitle_no_answer')}`,
          variant: 0,
          type: lowerFeedbackType
        })
      }

      setFeedbackTitle(
        t(
          !isAnsweredOnTime.current
            ? 'app_practice_incorrect_feedback_title_no_answer'
            : `activity_${lowerFeedbackType}_feedback_title_${randomFeedback.variant}`
        )
      )

      setFeedbackSubtitle(
        t(
          !isAnsweredOnTime.current
            ? 'app_practice_incorrect_feedback_subtitle_no_answer'
            : `activity_${lowerFeedbackType}_feedback_subtitle_${randomFeedback.variant}`
        )
      )

      setFeedbackIcon(lowerFeedbackType + '_' + randomFeedback.variant)

      onActivityEvent({
        name: isUserCorrect
          ? 'Sound Quiz Correct Answer'
          : 'Sound Quiz Error Answer',
        properties: {}
      })
    }
  }

  const handleAnswerFeedback = (isUserCorrect) => {
    const lowerFeedbackType = isUserCorrect
      ? FEEDBACK_TYPE.CORRECT.toLowerCase()
      : FEEDBACK_TYPE.INCORRECT.toLowerCase()
    const randomFeedback = getRandomFeedback(lowerFeedbackType)
    const defaultFeedback = activityStore?.feedback?.errorMessages?.[0]

    setAnswerFeedback(
      isUserCorrect,
      lowerFeedbackType,
      randomFeedback,
      defaultFeedback
    )

    setIsFeedbackVisible(true)
  }

  // Comprueba la respuesta del usuario
  const checkAnswer = (forcedResult, isFromDummy) => {
    if (isFromDummy && forcedResult !== undefined) {
      activityStore.setResponse(
        forcedResult ? activityStore.validation.value : ['']
      )

      if (isScaffold && isScaffoldMobileVersion) {
        setIsDummyAnswer(isFromDummy)
        // Se podría saltar desde dummy el scaffold si se lanza este evento, si se hace dar tiempo para las animaciones
        // onActivityEvent({name: 'Check Answer', ...
        return
      }
    }

    setIsSolutionShowed(false)

    if (!isScaffold && activityStore?.hints?.isVisible) {
      activityStore.hints.hideHint()
    }

    let isUserCorrect = null

    if (
      isFromDummy ||
      !isScaffold ||
      scaffoldCurrentQuestion?.isMain === true
    ) {
      isUserCorrect = processMainCheck(forcedResult)
    } else {
      activityStore.checkAnswers()

      isUserCorrect = !isFromDummy
        ? activityStore.getResults() === 1
          ? 1
          : 0
        : forcedResult

      setIsScaffoldComplete(scaffoldCurrentQuestion.isLast)
    }

    setIsAnswerCorrect(isUserCorrect)
    handleAnswerFeedback(isUserCorrect)
  }

  const parseStudentResponse = (response) => {
    if (!response) return {}

    if (typeof response === 'string') {
      try {
        return JSON.parse(response)
      } catch (e) {
        return {}
      }
    }

    return response
  }

  useEffect(() => {
    if (
      activityType === ACTIVITY_TYPES.QUIZ &&
      activityStore &&
      showSolutionQuestionQuizPreview !== undefined
    ) {
      if (showSolutionQuestionQuizPreview) {
        activityStore.checkAnswers()
        activityStore.showSolutions()
      } else {
        // null indicates that the student did not answer the question
        if (
          (studentResponse || studentResponse === null) &&
          studentResponse?.length > 0
        ) {
          activityStore.reset()
          studentResponse !== null &&
            activityStore.setResponse(parseStudentResponse(studentResponse))
          activityStore.checkAnswers()
        } else {
          activityStore?.reset && activityStore.reset()
        }
      }
    }
  }, [showSolutionQuestionQuizPreview])

  useEffect(() => {
    if (
      activityType === ACTIVITY_TYPES.QUIZ &&
      activityStore &&
      studentResponse !== undefined
    ) {
      // null indicates that the student did not answer the question
      if (
        (studentResponse || studentResponse === null) &&
        studentResponse?.length > 0
      ) {
        activityStore.reset()
        studentResponse !== null &&
          activityStore.setResponse(parseStudentResponse(studentResponse))
        activityStore.checkAnswers()
      }
    }
  }, [activityStore, studentResponse])

  const showScaffoldFirstStep = () => {
    checkAnswer(false)
  }

  const scaffoldNext = () => {
    scaffoldInnerNext()
    setIsFeedbackVisible(false)
    setFeedbackBoxMessage('')
    setFeedbackIndicatorObject({})
    setIsCheckButtonEnabled(false)
    setIsAnswerCorrect(false)
  }

  const resetActivity = () => {
    setIsDummyAnswer(false)
    setIsFeedbackVisible(false)
    setFeedbackBoxMessage('')
    setFeedbackIndicatorObject({})
    if (activityType === ACTIVITY_TYPES.PREVIEW) {
      activityStore.reset()
    }
    initializeActivity(false)
  }

  const handleUserChange = (event) => {
    setIsCheckButtonEnabled(true)
  }

  const getRandomFeedback = (type) => {
    const lastVariant = parseInt(
      activityStorage.get('bb_' + type + '_feedback')
    )
    let randomVariant = Math.floor(Math.random() * FEEDBACK_TOTAL_VARIANTS)

    if (randomVariant === lastVariant) randomVariant++
    if (randomVariant > FEEDBACK_TOTAL_VARIANTS - 1) randomVariant = 0

    activityStorage.set('bb_' + type + '_feedback', randomVariant)

    return {
      title: t('activity_' + type + '_feedback_title_' + randomVariant),
      message: t('activity_' + type + '_feedback_subtitle_' + randomVariant),
      variant: randomVariant
    }
  }

  // QUIZ
  useEffect(() => {
    if (finishedTimeQuestion) {
      finishTimerQuiz()
    }
  }, [finishedTimeQuestion])

  useEffect(() => {
    if (isResponseSend) {
      isAnsweredOnTime.current = true
      setIsCheckButtonEnabled(false)
      setIsDisabledActivity(true)

      setFeedbackBoxMessage(t('app_practice_send_answer'))

      // Enviar parámetros de la respuesta a la API
      onActivityEvent({
        name: 'Check Answer Quiz',
        properties: {
          resultAnswer: activityStore.getResults() === 1 ? 1 : 0,
          lemonadeResponseData: activityStore.getUserResponse()
        }
      })

      onActivityEvent({
        name: 'Sound Quiz Send Answer',
        properties: {}
      })

      onActivityEvent({
        name: 'Analisys Practice Activity Answered Quiz',
        properties: {
          is_answered: true,
          result: activityStore.getResults() === 1 ? 1 : 0
        }
      })
    }
  }, [isResponseSend])

  useEffect(() => {
    if (showSolutionQuestion) {
      checkAnswer()

      if (!isResponseSend) {
        onActivityEvent({
          name: 'Check Answer Quiz',
          properties: {
            resultAnswer: 0,
            lemonadeResponseData: []
          }
        })

        onActivityEvent({
          name: 'Analisys Practice Activity Answered Quiz',
          properties: {
            is_answered: false,
            result: 0
          }
        })
      }
    }
  }, [showSolutionQuestion])

  const finishTimerQuiz = () => {
    setIsCheckButtonEnabled(false)
    setIsDisabledActivity(true)
  }

  return {
    feedbackBoxMessage,
    feedbackIndicatorObject,
    isFeedbackVisible,
    setIsFeedbackVisible,
    checkAnswer,
    resetActivity,
    handleUserChange,
    showScaffoldFirstStep,
    scaffoldNext,
    setIsResponseSend,
    isResponseSend,
    feedbackTitle,
    feedbackSubtitle,
    feedbackIcon,
    isDummyAnswer
  }
}
