import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as SvgScaffold } from '../../../assets/icons/chevrons-up.svg'
import { FEEDBACK_TYPE } from '../../../constants/FeedbackConstants'
import { BREAKPOINTS } from '../../../constants/uiConstants'
import { useActivityContext } from '../../../context/ActivityContext/useActivityContext'
import ActivityButton from '../../atoms/ActivityButton/ActivityButton'
import FeedbackBox from '../../atoms/FeedbackBox/FeedbackBox'

const useScaffoldMobileContainer = ({
  checkAnswer,
  onActivityEvent,
  isDummyAnswer,
  feedbackBoxMessage,
  hasCheckExerciseAPiResponded,
  feedbackIndicatorObject,
  exerciseGuid,
  requiresAudioAssistance,
  getTextAudio,
  mathRenderEngine,
  pathAssetsSchool,
  footerWidth
}) => {
  const { t } = useTranslation()

  const {
    isPracticeType,
    scaffoldCurrentQuestion,
    activityType,
    setScaffoldCurrentQuestion,
    setActivityStore
  } = useActivityContext()

  const [scaffoldStore, setScaffoldStore] = useState(null)
  const [questionStore, setQuestionStore] = useState(null)
  const [canUserCheck, setCanUserCheck] = useState(false)
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(false)
  const [isSolutionShowed, setIsSolutionShowed] = useState(false)
  const [showFeedbackIndicator, setShowFeedbackIndicator] = useState(false)
  const [generateFeedbackBox, setGenerateFeedbackBox] = useState(false)
  const [feedbackBoxDataList, setFeedbackBoxDataList] = useState([])

  useEffect(() => {
    const handleNavigationNextButtonClick = (e) => {
      if (e.target.closest('.lemo-scaffolding-navigation-next-button')) {
        setShowFeedbackIndicator(false)
      }
    }
    window.addEventListener('click', handleNavigationNextButtonClick)

    return () => {
      window.removeEventListener('click', handleNavigationNextButtonClick)
    }
  }, [])

  // Esto es para poder interactuar con el header cuando el scaffold está minimizado
  useEffect(() => {
    const scaffoldMobileLayout = document.querySelector(
      '.lemo-scaffolding-mobile-layout'
    )

    const lemoQuestion = document.querySelector(
      '.lemo-question-wrapper .lemo-question'
    )

    if (scaffoldStore?.isOpen !== undefined) {
      if (scaffoldStore.isOpen) {
        scaffoldMobileLayout && (scaffoldMobileLayout.style.zIndex = 200) // Es el original indicado por Lemonade
        lemoQuestion && (lemoQuestion.style.zIndex = 0)
      } else {
        scaffoldMobileLayout && (scaffoldMobileLayout.style.zIndex = 1)
        lemoQuestion && (lemoQuestion.style.zIndex = 'revert-layer')
      }
    }
  }, [scaffoldStore?.isOpen])

  useEffect(() => {
    if (scaffoldStore) {
      const questionIndex = scaffoldStore.currentQuestionIndex
      const questionTotal = scaffoldStore.questionList.length

      setScaffoldCurrentQuestion({
        index: questionIndex,
        isMain: questionIndex === 0,
        isLast: questionIndex === questionTotal - 1
      })
    }
  }, [scaffoldStore?.currentQuestion])

  useEffect(() => {
    if (generateFeedbackBox && feedbackBoxMessage) {
      setGenerateFeedbackBox(false)

      setFeedbackBoxDataList((prevList) => [
        ...prevList,
        {
          questionIndex: scaffoldStore.currentQuestionIndex,
          feedbackType: isAnswerCorrect
            ? FEEDBACK_TYPE.CORRECT
            : FEEDBACK_TYPE.INCORRECT,
          content: feedbackBoxMessage
        }
      ])
    }
  }, [feedbackBoxMessage, generateFeedbackBox])

  useEffect(() => {
    if (isDummyAnswer) {
      setCanUserCheck(true)

      setTimeout(() => {
        const scaffoldCheckButton = document.querySelector('.check-button')
        if (scaffoldCheckButton) {
          scaffoldCheckButton.click()
        }
      }, 200)
    }
  }, [isDummyAnswer])

  const toogleMainQuestionControls = (hide) => {
    const mainControls = document.querySelector('.main-question-controls')
    if (mainControls) {
      mainControls.style.display = hide ? 'none' : 'flex'
    }
  }

  // En este objeto defino todos los botones que quiero personalizar
  const customButtons = {
    checkAnswers: ({ onClick, isDisabled }) => {
      const checkButtonAction = () => {
        const isMain = scaffoldCurrentQuestion.isMain
        // const isLast = scaffoldCurrentQuestion.isLast
        onClick()
        const _isAnswerCorrect = questionStore.getResults()
        setIsAnswerCorrect(_isAnswerCorrect)

        checkAnswer(_isAnswerCorrect)
        checkFeedback(isMain, _isAnswerCorrect)

        // Al pasar de la pregunta principal al primer paso de scaffold, no abre del todo
        // con esto se soluciona (aunque sería mejor desde Lemonade)
        if (isMain) {
          if (!_isAnswerCorrect) {
            toogleMainQuestionControls(true)
          }
          setTimeout(() => scaffoldStore.open(), 200)
        }
      }

      return (
        <ActivityButton
          text={t('activity_check_button')}
          disabled={isDisabled || !canUserCheck}
          action={checkButtonAction}
          customClass={'check-button'}
        />
      )
    },
    reset: ({ onClick, isDisabled }) => <div style={{ display: 'none' }}></div>,
    IDK: ({ onClick, isDisabled }) => {
      const helpButtonAction = () => {
        toogleMainQuestionControls(true)
        const isMain = scaffoldCurrentQuestion.isMain
        onClick()
        checkAnswer(0)

        if (isMain) {
          setTimeout(() => scaffoldStore.open(), 200)
        }
      }

      return (
        <ActivityButton
          icon={<SvgScaffold />}
          type={3}
          text={
            footerWidth < BREAKPOINTS.tablet
              ? ''
              : t('activity_scaffold_button')
          }
          swapIconText={true}
          keepActive={false}
          minimunSize={true}
          action={helpButtonAction}
          disabled={isDisabled}
          customClass={'help-button'}
        />
      )
    },
    showSolution: ({ onClick, isDisabled, isShowingSolutions }) => {
      const solutionButtonAction = () => {
        onClick()
        setIsSolutionShowed(true)
        setShowFeedbackIndicator(false)
      }

      return (
        <ActivityButton
          text={t(
            !isShowingSolutions
              ? 'activity_solution_button'
              : 'activity_user_solution_button'
          )}
          disabled={isDisabled}
          action={solutionButtonAction}
          type={!isSolutionShowed ? 0 : 2}
          customClass={
            canShowFeedbackIndicator()
              ? 'solution-button indicator-showing'
              : 'solution-button'
          }
        />
      )
    },
    next: ({ onClick, isDisabled }) => {
      const nextButtonAction = () => {
        setShowFeedbackIndicator(false)
        onClick()
      }

      return !scaffoldCurrentQuestion.isLast ? (
        <ActivityButton
          text={t('activity_next_button')}
          action={nextButtonAction}
          disabled={isDisabled}
          customClass={'next-button'}
        />
      ) : (
        <div style={{ display: 'none' }}></div>
      )
    },
    restartScaffolding: ({ onClick, isDisabled }) => {
      const restartButtonAction = () => {
        setShowFeedbackIndicator(false)
        toogleMainQuestionControls(false)
        onClick()

        if (isPracticeType) {
          onActivityEvent({ name: 'Continue Clicked' })
        }
      }

      return (
        <ActivityButton
          text={t(
            isPracticeType
              ? 'activity_continue_button'
              : 'activity_restart_button'
          )}
          disabled={isPracticeType ? !hasCheckExerciseAPiResponded : false}
          isLoading={isPracticeType ? !hasCheckExerciseAPiResponded : false}
          action={restartButtonAction}
          customClass={'restart-button'}
        />
      )
    }
  }

  const getScaffoldStore = (_scaffoldStore) => {
    // window.scaffoldStore = _scaffoldStore
    setScaffoldStore(_scaffoldStore)
  }

  const getQuestionStore = (_questionStore) => {
    // window.questionStore = _questionStore
    setActivityStore(_questionStore)
    setQuestionStore(_questionStore)
    setCanUserCheck(false)
    setIsSolutionShowed(false)
  }

  const handleUserChange = () => {
    setCanUserCheck(true)
  }

  const checkFeedback = (isMain, _isAnswerCorrect) => {
    if (!isMain || _isAnswerCorrect) {
      setShowFeedbackIndicator(true)
      setGenerateFeedbackBox(true)
    }
  }

  const canShowFeedbackIndicator = () => {
    return (
      showFeedbackIndicator &&
      footerWidth >= BREAKPOINTS.tablet &&
      scaffoldStore?.isOpen === true &&
      typeof feedbackIndicatorObject?.message === 'string'
    )
  }

  const renderFeedbackBox = (index) => {
    const currentFeedbackBoxData = feedbackBoxDataList.find(
      (feedbackBoxData) =>
        feedbackBoxData.questionIndex === scaffoldStore?.currentQuestionIndex
    )

    if (!currentFeedbackBoxData) return null

    return (
      <FeedbackBox
        feedbackType={currentFeedbackBoxData.feedbackType}
        content={currentFeedbackBoxData.content}
        onActivityEvent={onActivityEvent}
        panelInformationType={'activity_feedback'}
        exerciseGuid={exerciseGuid}
        requiresAudioAssistance={requiresAudioAssistance}
        getTextAudio={getTextAudio}
        mathRenderEngine={mathRenderEngine}
        pathAssetsSchool={pathAssetsSchool}
      />
    )
  }

  return {
    getScaffoldStore,
    getQuestionStore,
    handleUserChange,
    renderFeedbackBox,
    canShowFeedbackIndicator,
    customButtons,
    scaffoldStore,
    activityType
  }
}

export default useScaffoldMobileContainer
