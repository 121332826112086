import { React, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dailyRewardImg from '../../../../../assets/img/metaberry_ui/daily-reward2.svg'
import coinImg from '../../../../../assets/img/metaberry_ui/coin.svg'
import chronometerImg from '../../../../../assets/img/metaberry_ui/chronometer.svg'
import './MissionDailyRewardCard.scss'
import translate from '../../../../../i18n/translate'
import circleBackground from '../../../../../assets/img/metaberry_ui/circle-background.svg'

const MissionDailyRewardCard = ({ onClickGetReward }) => {
  const [leftHours, setLeftHours] = useState(23)
  const [leftMinutes, setLeftMinutes] = useState(59)
  const [leftTimeBarWidth, setLeftTimeBarWidth] = useState(100)
  const canGetDailyReward = useSelector(
    (state) => state.dailyGoal.canGetDailyReward
  )
  const dailyRewardDate = useSelector(
    (state) => state.dailyGoal.nextDailyReward
  )
  useEffect(() => {
    calculateTimeLeft()

    const leftTimer = setInterval(() => {
      calculateTimeLeft()
    }, 60000)

    document.addEventListener('visibilitychange', onVisibilitychange)

    return () => {
      clearInterval(leftTimer)
    }
  }, [])

  const onVisibilitychange = () => {
    if (document.visibilityState === 'visible') {
      calculateTimeLeft()
    }
  }

  const extractHoursAndMinutes = (milliseconds) => {
    const hours = Math.floor(milliseconds / (1000 * 60 * 60))
    const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60))

    return { hours, minutes }
  }

  const calculateTimeLeftPercentage = (milliseconds) => {
    const MILLIS_IN_24_HOURS = 24 * 60 * 60 * 1000

    let percentage = Math.round((milliseconds / MILLIS_IN_24_HOURS) * 100)

    if (percentage < 0) percentage = 0
    if (percentage > 100) percentage = 100

    return percentage
  }

  const calculateTimeLeft = () => {
    const millisecondsLeft = new Date(dailyRewardDate) - new Date()
    const { hours, minutes } = extractHoursAndMinutes(millisecondsLeft)

    setLeftTimeBarWidth(calculateTimeLeftPercentage(millisecondsLeft) + '%')
    setLeftHours(hours)
    setLeftMinutes(minutes)
  }

  return (
    <div className="mission-daily-reward-card">
      <div className="mission-daily-reward_circle-background">
        <img src={circleBackground} alt="" />
      </div>
      <div className="mission-daily-reward-card_image">
        <img src={dailyRewardImg} alt="" />
        {/* <span>{'+' + amountDailyReward}</span> */}
        <span>{'+1'}</span>
      </div>
      <div className="mission-daily-reward-card_information">
        <div className="mission-daily-reward-card_title">
          {translate(
            canGetDailyReward
              ? 'missions_daily_reward_title-can-get'
              : 'missions_daily_reward_title-cant-get'
          )}
        </div>
        <div className="mission-daily-reward-card_message">
          {translate(
            canGetDailyReward
              ? 'missions_daily_reward_message'
              : 'missions_daily_reward_next_message'
          )}
        </div>
      </div>

      {canGetDailyReward && (
        <div className="mission-daily-reward-card_actions">
          <div
            className="mission-daily-reward-card_get-button"
            onClick={onClickGetReward}
          >
            {translate('missions_daily_reward_get_button')}
          </div>
        </div>
      )}

      {!canGetDailyReward && (
        <>
          <div className="missions_daily_reward_timer">
            <img
              className="missions_daily_reward_timer-chronometer"
              src={chronometerImg}
              alt=""
            />

            <div className="missions_daily_reward_timer-bar">
              <div
                className="missions_daily_reward_timer-bar-inner"
                style={{ width: leftTimeBarWidth }}
              ></div>
            </div>

            <img
              className="missions_daily_reward_timer-coin"
              src={coinImg}
              alt=""
            />
          </div>
          <div className="missions_daily_reward_timer-message">
            {translate('missions_daily_reward_time-left', {
              hours: leftHours,
              minutes: leftMinutes
            })}
          </div>
        </>
      )}
    </div>
  )
}

export default MissionDailyRewardCard
