import React, { useEffect, useState } from 'react'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import { ReactComponent as MessageFrameSvg } from '../../../../../assets/img/metaberry_ui/information-frame.svg'
import translate from '../../../../../i18n/translate'
import { ReactComponent as AudioBtn } from '../../../../../assets/img/metaberry_ui/sound_on.svg'
import { ReactComponent as CloseIcon } from '../../assets/close_icon.svg'
import useSound from '../../../../views/MetaberryView/hooks/useSound'
import { getSoundAssistance } from '../../../../../services/settingsService'
import { Analysis } from '../../../../../services/analysisService'
import EmptyDot from '../../../../../assets/img/metaberry_ui/empty-dot.svg'
import Dot from '../../../../../assets/img/metaberry_ui/dot.svg'
import { store } from '../../../../../store/configureStore'

const OnboardingFirstMovesPanel1 = ({ handleCheck, pathSchool }) => {
  const { renderSoundFXController, playSoundEffect } = useSound()
  const langCode = getCurrentLangString()
  const audioFile = 'onBoardingFirstMoves01_' + langCode
  const audioFile2 = 'onBoardingFirstMoves02_' + langCode
  const [firstMovesAnimation, setFirstMovesAnimation] = useState()
  const firstBuildingImage = store.getState().metaberry.firstBuildingImage

  const [firstDotActive, setFirstDotActive] = useState(true)
  const [secondAudioPlayed, setSecondAudioPlayed] = useState(false)

  useEffect(() => {
    getSoundAssistance() && playSoundEffect(audioFile)

    setFirstMovesAnimation(
      pathSchool
        ? `/assets/graphics/gamification/${pathSchool}/first_moves.gif`
        : `/assets/graphics/gamification/first_moves.gif`
    )
  }, [])

  const handleAudio = () => {
    Analysis.sendEvent(Analysis.EVENT.REQUEST_TEXT_TO_SPEECH, {
      type: 'onboarding'
    })

    Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['TTS Clicked'], {
      object: 'onboarding',
      ui_element_id: 'onboarding_first_moves_1',
      seed_id: undefined
    })

    playSoundEffect(audioFile)
  }

  const handleButton = () => {
    if (firstDotActive) {
      changeActiveDot(false)
    } else {
      handleCheck()
    }
  }

  const changeActiveDot = (firstDot) => {
    if (!secondAudioPlayed && !firstDot) {
      getSoundAssistance() && playSoundEffect(audioFile2)
      setSecondAudioPlayed(true)
    }

    setFirstDotActive(firstDot)
  }

  return (
    <>
      <div className="onboarding-popup__backdrop" onClick={handleButton}></div>
      <div className="onboarding-popup__container centered">
        <div
          className="onboarding-popup__close-icon-new"
          onClick={handleButton}
        >
          <CloseIcon />
        </div>
        <div className="onboarding-popup__text-container">
          <MessageFrameSvg
            preserveAspectRatio="none"
            className="onboarding-popup__text-background"
          />
          <p className="onboarding-popup__text">
            {firstDotActive
              ? translate('onboarding_first_moves_text_1')
              : translate('onboarding_first_moves_text_2')}
          </p>
          <div className="onboarding-popup__audio">
            {renderSoundFXController()}
            <AudioBtn
              onClick={() => {
                handleAudio()
                if (firstDotActive) {
                  playSoundEffect(audioFile)
                } else {
                  playSoundEffect(audioFile2)
                }
              }}
            />
          </div>
        </div>

        <div className="onboarding-popup__image-container">
          <img
            className={`${
              firstDotActive
                ? 'onboarding-popup__image'
                : 'onboarding-popup__second-image'
            }`}
            src={firstDotActive ? firstMovesAnimation : firstBuildingImage}
            alt=""
          />
        </div>
        <div className="onboarding-popup__dots-container">
          <button
            className="onboarding-popup__dot"
            onClick={() => changeActiveDot(true)}
          >
            <img src={firstDotActive ? Dot : EmptyDot} alt="" />
          </button>
          <button
            className="onboarding-popup__dot"
            onClick={() => changeActiveDot(false)}
          >
            <img src={firstDotActive ? EmptyDot : Dot} alt="" />
          </button>
        </div>
        <div
          className="onboarding-popup__button"
          onClick={handleButton}
          data-testid="onboarding_first_moves_button_1"
        >
          {firstDotActive
            ? translate('onboarding_first_moves_button_1')
            : translate('onboarding_first_moves_button_2')}
        </div>
      </div>
    </>
  )
}
export default OnboardingFirstMovesPanel1
