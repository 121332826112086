/* eslint-disable no-constant-condition */
/* eslint-disable no-unused-vars */
import { createElement as createReactElement } from 'react'
import { render as renderReact, unmountComponentAtNode } from 'react-dom'
import Phaser from 'phaser'
import Map from '../components/Map'
import Character from '../components/Character'
import UnitInformation from '../components/UnitInformation'
import LessonInformation from '../components/LessonInformation'
import QAElement from '../components/QAElement/QAElement'
import { store } from '../../../../store/configureStore'
import {
  setIsPhaserVisible,
  setIsGameUIVisible,
  setIsGameFromPop,
  enterUnit,
  enterLesson,
  exitUnit,
  setMoveCameraToPlayer,
  setMoveCameraToObject,
  setFirstBuildingImage,
  resetRespawn
} from '../../../../store/MetaberrySlice/MetaberrySlice'
import { startChallengeToStatus } from '../../../../util/typesTransform'

import getLessons from '../utils/getLessons'
import { initLessons } from '../../../../store/UnitsSlice/UnitsSlice'
import areObjectsEqual from '../../../../util/areObjectsEqual'
import { getCourseGuid } from '../../../../assets/data/api'
import { createEndPoint, getToken } from '../../../../services/util'

import MetaLessonPanel from '../../../components/MetaLessonPanel/MetaLessonPanel'
import MetaUnitPanel from '../../../components/MetaUnitPanel/MetaUnitPanel'
// import MetaExitPanel from '../../../components/MetaExitPanel/MetaExitPanel'
import MapCourseBackButton from '../../../components/MapCourseBackButton/MapCourseBackButton'
import { v4 as uuidv4 } from 'uuid'
import {
  getRespawn,
  updateRespawn,
  getIsRankingShowed,
  getUserAvatar
} from '../../../../services/settingsService.js'
import { updateBatteryPieces } from '../../../../services/popupManagerService'
import { Analysis } from '../../../../services/analysisService'
import translate from '../../../../i18n/translate'
import { getPetAvatar } from '../../../../services/rewardsService'
import {
  getAloneUserData,
  getFamilyUserData
} from '../../../../services/userTypeService'
import { errorRedirection } from '../../../../services/errorService.js'
import { increaseCountProgress } from '../../../../util/loadingProgressBar.js'
import {
  setFirstBateryChargedPending,
  setFirstBrokenBateryPending,
  setFirstUnlockedLessonPending,
  setFirstUnlockedUnitPending,
  setFirstBateryCompletePending,
  setRewardBatteryUnlockPending
} from '../../../../store/PopupManagerSlice/PopupManagerSlice'

const NEAR_ZOOM_COURSE_MAP_VISIBLE_TILES = 12
const NEAR_ZOOM_UNIT_MAP_VISIBLE_TILES = 8
const COURSE_BACKGRUIND_COLOR = '#6881D5'
const UNITS_BACKGRUIND_COLOR = '#000000'

/* Version 1 (funciona, pero hay mucho salto entre zooms)
const FIXED_ZOOMS = [
  0.03125, 0.0625, 0.125, 0.25, 0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5,
  5.5, 6, 6.5, 7, 7.5, 8, 8.8, 9, 9.5, 10, 10.5, 11, 11.5, 12
]

/* Versión 2, parece funcionar por lo que voy a forzar un poco más
const FIXED_ZOOMS = [
  0.03125, 0.0625, 0.125, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 2.25, 2.5,
  2.75, 3, 3.25, 3.5, 3.75, 4, 4.25, 4.5, 4.75, 5, 5.25, 5.5, 5.75, 6, 6.25,
  6.5, 6.75, 7, 7.25, 7.5, 7.75, 8, 6.25, 8.5, 8.75, 9, 9.25, 9.5, 9.75, 10
]
*/

/* Versión 2, parece funcionar por lo que voy a forzar un poco más
const FIXED_ZOOMS = [
  0.03125, 0.0625, 0.125, 0.25, 0.375, 0.5, 0.625, 0.75, 0.875, 1, 1.125, 1.25,
  1.375, 1.5, 1.625, 1.75, 1.875, 2, 2.125, 2.25, 2.375, 2.5, 2.625, 2.75,
  2.875, 3, 3.125, 3.25, 3.375, 3.5, 3.625, 3.75, 3.875, 4, 4.125, 4.25, 4.375,
  4.5, 4.625, 4.75, 4.875, 5, 5.125, 5.25, 5.375, 5.5, 5.625, 5.75, 5.875, 6,
  6.125, 6.25, 6.375, 6.5, 6.625, 6.75, 6.875, 7, 7.125, 7.25, 7.375, 7.5,
  7.625, 7.75, 7.875, 8, 8.125, 8.25, 8.375, 8.5, 8.625, 8.75, 8.875, 9
]
*/
// Versión 3
const FIXED_ZOOMS = [
  // eslint-disable-next-line prettier/prettier
  0.03125, 0.0625, 0.125, 0.1875, 0.25, 0.3125, 0.375, 0.4375, 0.5, 0.5625,
  0.625, 0.6875, 0.75, 0.8125, 0.875, 0.9375, 1, 1.0625, 1.125, 1.1875, 1.25,
  1.3125, 1.375, 1.4375, 1.5, 1.5625, 1.625, 1.6875, 1.75, 1.8125, 1.875,
  1.9375, 2, 2.0625, 2.125, 2.1875, 2.25, 2.3125, 2.375, 2.4375, 2.5, 2.5625,
  2.625, 2.6875, 2.75, 2.8125, 2.875, 2.9375, 3, 3.0625, 3.125, 3.1875, 3.25,
  3.3125, 3.375, 3.4375, 3.5, 3.5625, 3.625, 3.6875, 3.75, 3.8125, 3.875,
  3.9375, 4, 4.0625, 4.125, 4.1875, 4.25, 4.3125, 4.375, 4.4375, 4.5, 4.5625,
  4.625, 4.6875, 4.75, 4.8125, 4.875, 4.9375, 5, 5.0625, 5.125, 5.1875, 5.25,
  5.3125, 5.375, 5.4375, 5.5, 5.5625, 5.625, 5.6875, 5.75, 5.8125, 5.875,
  5.9375, 6, 6.0625, 6.125, 6.1875, 6.25, 6.3125, 6.375, 6.4375, 6.5, 6.5625,
  6.625, 6.6875, 6.75, 6.8125, 6.875, 6.9375, 7, 7.0625, 7.125, 7.1875, 7.25,
  7.3125, 7.375, 7.4375, 7.5, 7.5625, 7.625, 7.6875, 7.75, 7.8125, 7.875,
  7.9375, 8, 8.0625, 8.125, 8.1875, 8.25, 8.3125, 8.375, 8.4375, 8.5, 8.5625,
  8.625, 8.6875, 8.75, 8.8125, 8.875, 8.9375, 9, 9.0625, 9.125, 9.1875, 9.25,
  9.3125, 9.375, 9.4375, 9.5, 9.5625, 9.625, 9.6875, 9.75, 9.8125, 9.875,
  9.9375, 10
  // eslint-disable-next-line prettier/prettier
]

const UPDATE_THRESHOLD = 160 // Umbral de actualización (160ms)

export default class World extends Phaser.Scene {
  constructor() {
    super('MainScene')

    ocLog(window._getTestTime() + ' - MainScene const i tms-')
    increaseCountProgress()

    window.localStorage.removeItem('bb_map_created')

    this.isPointerDown = false
    this.cursorPressed = { up: false, down: false, left: false, right: false }

    this.previousLessons = []

    this.portalActive = null

    this.joystick = null
    this.joystickKeys = null

    this.backCourseMapButtonWrapper = null
    this.backCourseMapButton = null
    // this.exitPanel = null
    this.unitPanelWrapper = null
    this.unitPanel = null
    this.lessonPanelWrapper = null
    this.lessonPanel = null
    this.lockedPortalsPreviousImages = []
    this.exitIcon = null

    this.cameraZoomedOut = false
    this.cameraNearZoom = null
    this.cameraFarZoom = null

    this.unsubscriberRedux = null

    this.isFirstMapLoad = true

    this.isLoading = true
    this.loadingJustFinished = false
    this.loadingFinished = true

    this.debounceUpdateLessons = null
    this.debouncePlayerAvatarChange = null
    this.debouncePetAvatarChange = null
    this.debounceUpdatePanel = null
    this.cameraMoving = false

    this.isCourseMap = false

    this.tryAgainUpdateLessonsCounter = 0

    this.isRankingShowed = getIsRankingShowed()

    this.grassStepsSoundPlayer = null
    this.grassStepsSoundPet = null
    this.woodStepsSoundPlayer = null
    this.woodStepsSoundPet = null

    window.addEventListener('toggle-birdeye', this.birdeye.bind(this))

    window.addEventListener('player-avatar-change', async () => {
      clearTimeout(this.debouncePlayerAvatarChange)
      this.debouncePlayerAvatarChange = setTimeout(async () => {
        this.createCompleted = false
        await this.player.changeTexture(
          getUserAvatar().url,
          getUserAvatar().sprite_json
        )
        this.createCompleted = true
      }, 200)
    })

    window.addEventListener('pet-avatar-change', async () => {
      clearTimeout(this.debouncePetAvatarChange)
      this.debouncePetAvatarChange = setTimeout(async () => {
        this.createCompleted = false

        const currentPetAvatar = getPetAvatar()
        // ocLog('Change - currentPetAvatar', currentPetAvatar)

        // Crear, modificar o destruir mascota
        if (currentPetAvatar) {
          if (this.playerPet) {
            const animationJson = currentPetAvatar.animation.spriteJson
            const animationParsedJson = animationJson
              ? JSON.parse(animationJson)
              : null
            await this.playerPet.changeTexture(
              currentPetAvatar.animation.spriteImage,
              animationParsedJson
            )
          } else {
            // ocLog('createPlayerPet- currentPetAvatar', currentPetAvatar)
            await this.createPlayerPet(this.cameras.main)
          }
        } else {
          if (this.playerPet) {
            this.playerPet.destroy()
            this.playerPet = null
          }
        }
        this.createCompleted = true
      }, 200)
    })

    this.boundBeforeUnload = (event) => {
      window.localStorage.removeItem('bb_game_initialized')
      this.setPlayerRespawn(event)
    }

    window.addEventListener('beforeunload', this.boundBeforeUnload.bind(this))
    window.addEventListener('user-logout', this.setPlayerRespawn.bind(this))
    // bb_game_initialized -> 1: MainsScene pasó por constructor, 2: MainScene terminó create
    window.localStorage.setItem('bb_game_initialized', 1)
    window.addEventListener('switch-player', this.setPlayerRespawn.bind(this))
    window.addEventListener('exit-game-page', this.setPlayerRespawn.bind(this))

    // Eventos para que desde fuera del juego (popups) se inhabilite o habilite su uso al usuario (del "juego en sí")
    window.addEventListener('disable-game', this.disableGame.bind(this))
    window.addEventListener('enable-game', this.enableGame.bind(this))

    window.addEventListener(
      'close-dom-elements',
      this.closeDomElements.bind(this)
    )

    window.addEventListener('popstate', (event) => {
      const phaserDomElements = document.querySelectorAll('.phaser-dom')
      if (phaserDomElements?.length && phaserDomElements.length > 0) {
        phaserDomElements.forEach((e) => e.remove())
      }

      this.unitPanelWrapper = null
      this.unitPanel = null
      this.lessonPanelWrapper = null
      this.lessonPanel = null
      this.backCourseMapButtonWrapper = null
      this.backCourseMapButton = null
      this.qaElements = []
    })

    Analysis.sendEvent(Analysis.EVENT.START_MAP)

    this.createCompleted = false

    // Para automatizar QA
    this.qaElements = []

    ocLog(window._getTestTime() + ' - MainScene const f tms-')
    increaseCountProgress()

    this.accumulatedDelta = 0 // Delta time acumulado
  }

  preload() {
    ocLog(window._getTestTime() + ' - MainScene preload i tms-')
    increaseCountProgress()

    // Estas imágenes se utilizarán en UnitInformation
    this.load.image('crown', 'assets/graphics/crown.png')
    this.load.image('lock', 'assets/graphics/lock.png')
    this.load.image('sign_top', 'assets/graphics/sign_top.png')
    this.load.image('sign_bottom', 'assets/graphics/sign_bottom.png')

    // Estas imágenes se utilizarán en LessonInformation
    this.load.image('disabled', 'assets/graphics/battery/disabled.png')
    this.load.image('blocked', 'assets/graphics/battery/blocked.png')
    this.load.image('install', 'assets/graphics/battery/install.png')
    this.load.image('empty', 'assets/graphics/battery/empty.png')
    this.load.image('init', 'assets/graphics/battery/init.png')
    this.load.image('charging', 'assets/graphics/battery/charging.png')
    this.load.image('complete', 'assets/graphics/battery/complete.png')
    this.load.image('start', 'assets/graphics/battery/start.png')
    this.load.image('broked_start', 'assets/graphics/battery/broked_start.png')
    this.load.image('exit', 'assets/graphics/exit.png')

    // Sonido de pisadas
    this.load.audio('grass-footsteps', ['assets/audio/grass-footsteps.mp3'])
    this.load.audio('wood-footsteps', ['assets/audio/wood-footsteps.mp3'])

    ocLog(window._getTestTime() + ' - MainScene preload f tms-')
    increaseCountProgress()
  }

  getCharacterStandingTile(character) {
    const characterCenter = character.getCenter()
    const row = Math.floor(
      (characterCenter.y - this.world.mapMarginY) / this.tileSize
    )
    const column = Math.floor(
      (characterCenter.x - this.world.mapMarginX) / this.tileSize
    )

    return [column, row]
  }

  async enterPortal(isFromBackCourseMapButton) {
    if (this.backCourseMapButton) {
      this.destroyBackCourseMapButton()
    }

    if (isFromBackCourseMapButton) {
      if (this.lessonPanel) {
        this.destroyLessonPanel()
      }

      /*
      if (this.exitPanel) {
        this.exitPanel.destroy()
        this.exitPanel = null
      }
      */
      if (this.exitIcon) {
        this.exitIcon.destroy()
        this.exitIcon = null
      }

      this.portalActive = this.portals.filter((item) => item.type === 'exit')[0]
    }

    if (this.portalActive) {
      this.portalActive.active = false

      // Lo clono porque puede darse el caso (MUY FORZADO) que termine de moverse el persoje por teclado
      //  habiendo dado ya a "entrar en portal" durante el "loading" lo que deja this.portalActive a null
      const portalClon = { ...this.portalActive }
      const portalType = portalClon.type.toLowerCase()

      await this.showLoading()
      this.createCompleted = false

      switch (portalType) {
        // Mapa curso (muestra unidades) a mapa unidad (muestra lecciones)
        case 'unit': {
          const standingPlayerTile = this.getCharacterStandingTile(this.player)

          // ocLog('enter unit portalClon', portalClon)

          store.dispatch(
            enterUnit({
              action: 'ENTER_UNIT',
              unitGuid: portalClon.unit_guid,
              unitName: portalClon.unit_name,
              unitExitTile: [
                standingPlayerTile[0] + 1,
                standingPlayerTile[1] + 1
              ]
            })
          )
          const enterLessonEvent = new CustomEvent('enter-lesson')
          window.dispatchEvent(enterLessonEvent)

          break
        }

        // Mapa unidad (muestra lecciones) a lección (actividades)
        case 'lesson': {
          Analysis.sendSegmentTrackEvent(
            Analysis.SEGMENT_EVENTS['Enter Lesson Clicked'],
            {
              lesson_guid: portalClon.lesson_guid,
              lesson_name: portalClon.lesson_name
            }
          )

          store.dispatch(
            enterLesson({
              action: 'ENTER_LESSON',
              lessonGuid: portalClon.lesson_guid,
              areLessonsUpdatedAfterPracticing: false
            })
          )
          break
        }

        // Sale de mapa de unidad (muestra lecciones) a mapa de curso (muestra unidades)
        case 'exit': {
          this.previousLessons = []

          const metaberryState = store.getState().metaberry

          const lessons = await getLessons()
          const unitEnteredEventObject = await this.getUnitEventData(
            metaberryState.unitGuid,
            lessons
          )

          Analysis.sendEvent(Analysis.EVENT.EXIT_UNIT, unitEnteredEventObject)

          Analysis.sendSegmentTrackEvent(
            Analysis.SEGMENT_EVENTS['Unit Exited'],
            unitEnteredEventObject
          )

          store.dispatch(exitUnit())

          const enterUnitEvent = new CustomEvent('enter-unit')
          window.dispatchEvent(enterUnitEvent)

          break
        }

        default:
          console.error('Portal desconocido')
          errorRedirection('/error-BBE-107', true)
      }

      if (portalType !== 'lesson') {
        // Cambia mapa ------------------
        // Destruir jugador
        this.player.setPath(null)
        this.player.sprite.setAlpha(0)
        this.player.destroy()
        this.player = null

        // Destruir mascota
        if (this.playerPet) {
          this.playerPet.setPath(null)
          this.playerPet.sprite.setAlpha(0)
          this.playerPet.destroy()
          this.playerPet = null
        }

        this.world.destroy()

        this.scene.run('BootScene')

        this.portalActive = null
      } else {
        // Abre actividades
        let challenge = portalClon.challenges[0]
        if (portalClon.status === 'broked_start') {
          const repairChallenges = portalClon.challenges.find(
            (auxChallenge) => auxChallenge.name === 'repair'
          )

          if (repairChallenges) {
            challenge = repairChallenges
          }
        }

        const currentState = store.getState()
        const courseGuid = await getCourseGuid()
        // const practiceSessionId = uuidv4()
        let playerGuid = null

        const aloneMemberData = getAloneUserData()
        if (aloneMemberData) {
          playerGuid = aloneMemberData?.guid
        } else {
          const currentPlayerData = getFamilyUserData()
          playerGuid = currentPlayerData?.guid
        }

        const startPracticeObject = {
          user_id: currentState.practice.mainUserId,
          player_id: playerGuid,
          program_id: currentState.practice.programId,
          course_id: courseGuid,
          // practice_session_id: practiceSessionId, de back al obtener ejercicio, aqui aun no
          unit_id: portalClon.unit_guid,
          lesson_id: portalClon.lesson_guid,
          lesson_name: portalClon.lesson_name,
          lesson_level: portalClon.lesson_level,
          lesson_status: portalClon.status
        }

        Analysis.sendEvent(
          Analysis.EVENT.START_ACTIVITY_SCREEN,
          startPracticeObject
        )

        Analysis.sendSegmentPageEvent(
          Analysis.SEGMENT_PAGE_CATEGORIES.Game,
          Analysis.SEGMENT_EVENTS['Challenge Intro Page Viewed'],
          startPracticeObject
        )

        this.createCompleted = true
        this.hideLoading()

        window.dispatchEvent(
          new CustomEvent('start-practice', { detail: portalClon })
        )
      }
    }
  }

  fadeScene(closeCircle, startFuncion, endFunction) {
    return new Promise((resolve, reject) => {
      const mainCamera = this?.cameras?.main
      if (!mainCamera) {
        endFunction && endFunction()
        resolve()
      } else {
        /*
          const topLeft = mainCamera.getWorldPoint(0, 0)
          No sé porqué pero despues de this.getLockedPortalsPreviousImages no coge el top left de lo que se ve
          Sin embargo las 2 siguiens parecen funcionar o no tener relevancia...
          No borro la línea comentada porque creo que es bueno recordarla
        */
        const topLeft = { x: 0, y: 0 } // mainCamera.getWorldPoint(0, 0) No sé porqué pero despues ^
        const bottomRight = mainCamera.getWorldPoint(
          this.game.canvas.width,
          this.game.canvas.height
        )
        let center = mainCamera.getWorldPoint(
          this.game.canvas.width / 2,
          this.game.canvas.height / 2
        )

        const circleRadius = Math.sqrt(
          center.x * center.x + center.y * center.y
        )

        if (this.player) {
          center = this.player.getCenter()
        }

        const startRadius = closeCircle ? circleRadius : 0
        const endRadius = closeCircle ? 0 : circleRadius
        const easeValue = closeCircle ? 'Quad.easeOut' : 'Quad.easeIn'
        let circ = new Phaser.Geom.Circle(center.x, center.y, startRadius)

        let fill = this.add
          .rectangle(
            topLeft.x,
            topLeft.y,
            bottomRight.x - topLeft.x,
            bottomRight.y - topLeft.y,
            0x3703a4
          )
          .setOrigin(0)
          .setDepth(20)
          .setVisible(!closeCircle)

        let gfx = this.make.graphics()
        let mask = gfx.createGeometryMask()

        mask.invertAlpha = true
        fill.setMask(mask)
        this.add.tween({
          targets: circ,
          duration: 800,
          delay: 100,
          ease: easeValue,
          props: { radius: endRadius },
          onUpdate: (tween) => {
            if (startFuncion) {
              startFuncion()
              startFuncion = null
            }

            if (!fill.visible) fill.setVisible(true)
            gfx.clear().fillStyle(0).fillCircleShape(circ)

            if (this.fromLoadingRectangle) {
              this.fromLoadingRectangle.destroy()
              this.fromLoadingRectangle = null
            }
          },
          onComplete: () => {
            endFunction && endFunction()

            mask.destroy()
            mask = null

            gfx.destroy()
            gfx = null

            fill.destroy()
            fill = null

            circ = null

            resolve()
          }
        })
      }
    })
  }

  async showLoading() {
    this.loadingFinished = false
    this.isLoading = true // Sino se pone a esta altura, durante disableGame vuelve a entrar en el portal

    this.disableGame()

    await this.fadeScene(
      true,
      () => {
        store.dispatch(setIsGameUIVisible(false))
      },
      () => {
        window.dispatchEvent(new CustomEvent('loading-started'))
        store.dispatch(setIsPhaserVisible(false))
      }
    )
  }

  hideLoading() {
    this.loadingJustFinished = true
    this.enableGame()
  }

  _createMouseEventListeners() {
    this.input.on('pointerdown', (pointer) => {
      this.isPointerDown = true
      this.onUserClick(pointer)
    })

    this.input.on('pointermove', () => {
      if (this.isPointerDown) {
        // TODO
      }
    })

    this.input.on('pointerup', () => {
      this.isPointerDown = false
    })

    this.input.keyboard.on('keydown', (event) => {
      // ocLog('key down: ' + event.code)
      // Si estaba en mitad de un path, pararlo en la tile que le toque
      this.player.setPath(null)

      switch (event.code) {
        case 'ArrowUp':
          this.cursorPressed.up = true
          break

        case 'ArrowDown':
          this.cursorPressed.down = true
          break

        case 'ArrowLeft':
          this.cursorPressed.left = true
          break

        case 'ArrowRight':
          this.cursorPressed.right = true
          break

        case 'Space':
        case 'Enter':
          // Se activa al levantar, pero dejo esto como referencia
          break
      }
    })

    this.input.keyboard.on('keyup', (event) => {
      // ocLog('key up: ' + event.code)
      switch (event.code) {
        case 'ArrowUp':
          this.cursorPressed.up = false
          break

        case 'ArrowDown':
          this.cursorPressed.down = false
          break

        case 'ArrowLeft':
          this.cursorPressed.left = false
          break

        case 'ArrowRight':
          this.cursorPressed.right = false
          break

        case 'Space':
        case 'Enter':
          this.enterPortalByKey()
          break

        case 'KeyE': {
          // window.dispatchEvent(new CustomEvent('reward-daily-goal'))
          break
        }
      }

      if (
        !this.cursorPressed.up &&
        !this.cursorPressed.down &&
        !this.cursorPressed.left &&
        !this.cursorPressed.right &&
        this.player
      ) {
        this.player.setPath(null)
      }
    })
  }

  async onUserClick(pointer) {
    if (pointer.leftButtonDown()) {
      const mousePosition = this.input.activePointer.positionToCamera(
        this.cameras.main
      )
      // const mousePosition = pointer.x, pointer.y;

      const mapTileWidth = this.world.tileMap.tileWidth
      const mapTileHeight = this.world.tileMap.tileHeight
      let destinationTileColumn = Math.floor(
        (mousePosition.x - this.world.mapMarginX) / mapTileWidth
      )
      let destinationTileRow = Math.floor(
        (mousePosition.y - this.world.mapMarginY) / mapTileHeight
      )

      // Si se hace sobre un edificio, el camino es hasta la entrada del edificio en lugar de la tile contreta
      const buildingDestination = this.changeDestinationIfBuilding(
        destinationTileColumn,
        destinationTileRow
      )
      if (buildingDestination) {
        destinationTileColumn = buildingDestination.column
        destinationTileRow = buildingDestination.row
      }

      const playerStandingTile = this.getCharacterStandingTile(this.player)

      const calculatedPath = await this.world.calculateBestPath(
        playerStandingTile[0],
        playerStandingTile[1],
        destinationTileColumn,
        destinationTileRow
      )

      this.player.setPath(calculatedPath)
    } else {
      this.player.setPath(null)
    }
  }

  changeDestinationIfBuilding(destinationTileColumn, destinationTileRow) {
    let buildingDestination = null
    let buildingIndex = null

    const tileProperties = this.world.getTilePropertiesByLayerAtColumnRow(
      destinationTileColumn,
      destinationTileRow,
      true
    )

    for (const layer in tileProperties) {
      if (tileProperties[layer].unitIndex !== undefined) {
        buildingIndex = tileProperties[layer].unitIndex
        break
      }
    }

    if (buildingIndex !== null) {
      // Buscar la entrada por indice en los portales
      for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
        const auxPortal = this.portals[p]
        if (auxPortal.type === 'unit' && auxPortal.index === buildingIndex) {
          buildingDestination = {
            column: auxPortal.spawn[0],
            row: auxPortal.spawn[1]
          }
          break
        }
      }
    }

    return buildingDestination
  }

  cameraToObject(objectX, objectY, callback) {
    if (this.unitPanel || this.lessonPanel) {
      this.cameraMoving = true

      document.querySelector(
        this.unitPanel ? '.meta-unit-panel' : '.meta-lesson-panel'
      ).style.visibility = 'hidden'
    }

    if (this.backCourseMapButton) {
      document.querySelector('.map-course-back-button').style.visibility =
        'hidden'
    }

    const camera = this.cameras.main
    const effectTimeMillis = 800

    camera.stopFollow()
    camera.removeBounds()

    camera.pan(
      objectX,
      objectY,
      effectTimeMillis,
      Phaser.Math.Easing.Linear,
      true,
      (_cameraP, _progressP) => {
        if (_progressP === 1) {
          camera.zoomTo(
            this.cameraNearZoom * 2,
            effectTimeMillis,
            Phaser.Math.Easing.Sine.In,
            true,
            (_cameraZ, _progressZ, _zoom) => {
              // Para que el centrado se ajuste al zoom durante los propios cambios de zoom
              this.adjustGameToCenter(_zoom, false)

              if (_progressZ === 1 && callback) {
                callback()
              }
            }
          )
        }
      }
    )
  }

  cameraToPlayer(callback) {
    const playerPosition = this.player.spriteCenter
    const camera = this.cameras.main
    const effectTimeMillis = 800

    camera.zoomTo(
      !this.cameraZoomedOut ? this.cameraNearZoom : this.cameraFarZoom,
      effectTimeMillis,
      Phaser.Math.Easing.Sine.Out,
      true,
      (_cameraZ, _progressZ, _zoom) => {
        // Para que el centrado se ajuste al zoom durante los propios cambios de zoom
        this.adjustGameToCenter(_zoom, true)

        if (_progressZ === 1) {
          camera.pan(
            playerPosition.x,
            playerPosition.y,
            effectTimeMillis,
            Phaser.Math.Easing.Linear,
            true,
            (_cameraP, _progressP) => {
              if (_progressP === 1) {
                camera.startFollow(this.player.sprite, true, 0.1, 0.1)

                if (callback) {
                  callback()
                }

                if (this.unitPanel || this.lessonPanel) {
                  this.cameraMoving = false
                  document.querySelector(
                    this.unitPanel ? '.meta-unit-panel' : '.meta-lesson-panel'
                  ).style.visibility = 'visible'
                }

                if (this.backCourseMapButton) {
                  document.querySelector(
                    '.map-course-back-button'
                  ).style.visibility = 'visible'
                }
              }
            }
          )
        }
      }
    )
  }

  areGameComponentsActives() {
    return (
      this?.input?.keyboard?.manager !== undefined &&
      this?.scene?.manager !== undefined &&
      this.input.keyboard.manager // Parece redundante, pero si se quita falla !!
    )
  }

  setKeyboardState(isEnabled) {
    if (!isEnabled) {
      for (const [key, value] of Object.entries(this.cursorPressed)) {
        this.cursorPressed[key] = false
      }
    }

    if (this.areGameComponentsActives()) {
      this.input.keyboard.manager.enabled = isEnabled
    }
  }

  disableGame(event) {
    // Desactiva todos los inputs
    this.game.input.enabled = false

    // Detener movimiento personaje
    if (this.player) {
      this.player.setPath(null)
      this.player.setDestination(null)
    }

    // Detener teclado
    this.setKeyboardState(false)

    this.isGameDisabled = true
  }

  enableGame(event) {
    // Reactiva todos los inputs
    this.game.input.enabled = true

    // Se comprueban otras posibles causas que no permitan activar el teclado
    const metaberryState = store.getState().metaberry
    if (
      !metaberryState.isPracticing &&
      !metaberryState.isInQuizz &&
      !metaberryState.isGameKeyboardBlocked
    ) {
      this.setKeyboardState(true)
    }

    this.isGameDisabled = false
  }

  reduxListener() {
    const currentState = store.getState()
    const metaberryState = currentState.metaberry

    // Comprobación para desbloquear teclado (sino phaser captura y bloquea todos los eventos)
    // ocLog('reduxListener', currentState)
    if (this.scene && this.areGameComponentsActives()) {
      if (
        metaberryState.isPracticing ||
        metaberryState.isInQuizz ||
        (metaberryState.isGameKeyboardBlocked && !metaberryState.isGameFromPop)
      ) {
        this.setKeyboardState(false)
        this.scene.pause()
        setTimeout(() => {
          try {
            this.sound.volume = 0
          } catch (ve) {}
        }, 200)
      } else if (!this.isGameDisabled || metaberryState.isGameFromPop) {
        // Se comprueba ^ (else if arriba) ^ que no esté desabilidato por otros motivos (this.isGameDisabled)
        if (metaberryState.isGameFromPop) {
          store.dispatch(setIsGameFromPop(false))
        }

        this.scene.resume()
        this.setKeyboardState(true)
        setTimeout(() => {
          try {
            this.sound.volume = 1
          } catch (sv) {}
        }, 200)
      }
    }

    // Comprobación para actualizar lecciones si han cambiado
    const currentGuid = metaberryState.unitGuid
    if (currentGuid) {
      const currentUnit = currentState.units.find(
        ({ unit_guid }) => unit_guid === currentGuid
      )

      const currentLessons =
        currentUnit && currentUnit.lessons ? currentUnit.lessons : []

      if (
        this.previousLessons.length > 0 &&
        currentLessons.length > 0 &&
        !areObjectsEqual(currentLessons, this.previousLessons)
      ) {
        clearTimeout(this.debounceUpdateLessons)
        this.debounceUpdateLessons = setTimeout(async () => {
          this.updateLessonsPortals(currentLessons)
        }, 400)
      }

      this.previousLessons = JSON.parse(JSON.stringify(currentLessons))
    }

    // Comprobación para mover camara si se solicita
    const moveCameraToObjectActive = metaberryState.moveCameraToObjectActive

    if (moveCameraToObjectActive === true) {
      store.dispatch(setMoveCameraToObject({ active: false }))

      const moveCameraToObjectIndex = metaberryState.moveCameraToObjectIndex
      if (moveCameraToObjectIndex !== -1) {
        const battery = this.portals[moveCameraToObjectIndex]
        if (battery) {
          const batteryX = (battery.batteryPosition[0] + 0.5) * this.tileSize
          const batteryY = (battery.batteryPosition[1] + 0.5) * this.tileSize
          this.cameraToObject(batteryX, batteryY, () => {
            store.dispatch(setMoveCameraToObject({ index: -1 }))
          })
        } else {
          store.dispatch(setMoveCameraToObject({ index: -1 }))
        }
      }
    }

    //  A jugador
    const moveCameraToPlayer = metaberryState.moveCameraToPlayer
    if (moveCameraToPlayer === true) {
      store.dispatch(setMoveCameraToPlayer(false))
      this.cameraToPlayer()
    }
  }

  // en login obtener de api y meter en X
  // en mover, entrar y salir actualizar en X
  //    en entrar o salir
  // como se si hay que usar lo que venía o lo que dicta el juego
  // en close mandar a api

  async setPlayerRespawn(event) {
    try {
      if (this.unsubscriberRedux) this.unsubscriberRedux()

      this.closeDomElements()

      const playerCenter = this.player.sprite.getCenter()
      const playerTileColumn =
        Math.floor((playerCenter.x - this.world.mapMarginX) / this.tileSize) + 1
      const playerTileRow =
        Math.floor((playerCenter.y - this.world.mapMarginY) / this.tileSize) + 1
      const metaberryState = store.getState().metaberry

      const updatedRespawn = {
        unit_guid: metaberryState.unitGuid,
        unit_name: metaberryState.unitName,
        row: playerTileRow,
        column: playerTileColumn
      }

      if (event.type === 'beforeunload') {
        updatedRespawn.timestamp = Date.now()
      }

      await updateRespawn(updatedRespawn)

      // Para sincronizar el final de esta función con el logout en logoutService
      event?.detail?.promiseResolve && event?.detail?.promiseResolve()
    } catch (e) {}
  }

  closeDomElements() {
    // Quitar paneles si estan abiertos
    try {
      this.destroyUnitPanel()
      this.destroyLessonPanel()
      this.destroyBackCourseMapButton()
      this.destroyAllQaElements()
    } catch (e) {}
  }

  craeteQaElement(identifier, destinationColumnAndRow, order) {
    // destinationColumnAndRow both start at 0
    const onClickAction = !destinationColumnAndRow
      ? () => {}
      : async () => {
          const standingTile = this.getCharacterStandingTile(this.player)
          const calculatedPath = await this.world.calculateBestPath(
            standingTile[0],
            standingTile[1],
            destinationColumnAndRow[0],
            destinationColumnAndRow[1]
          )

          this.player.setPath(calculatedPath)
        }

    const qaElementWrapper = this.createDomWrapper()
    const qaElement = createReactElement(QAElement, {
      qaIdentifier: identifier,
      qaAction: onClickAction,
      order
    })
    renderReact(qaElement, qaElementWrapper)
    document.querySelector('body').appendChild(qaElementWrapper)

    return { element: qaElement, wrapper: qaElementWrapper }
  }

  destroyQaElement(qaObject) {
    try {
      unmountComponentAtNode(qaObject.wrapper)
      document.querySelector('body').removeChild(qaObject.wrapper)
    } catch (e) {}
    qaObject.element = null
  }

  destroyAllQaElements() {
    if (this.qaElements?.length && this.qaElements.length > 0) {
      for (let i = this.qaElements.length - 1; i >= 0; i--) {
        this.destroyQaElement(this.qaElements[i])
      }
      this.qaElements = []
    }
  }

  craeteQaAutationElements() {
    // Destruir previos si existen
    this.destroyAllQaElements()

    this.qaElements.push(this.craeteQaElement('main-stone_1', [30, 9], 1))
    this.qaElements.push(this.craeteQaElement('main-stone_2', [26, 8], 2))
    this.qaElements.push(this.craeteQaElement('main-stone_3', [21, 6], 3))
    this.qaElements.push(this.craeteQaElement('main-door_juices', [27, 7], 4))
    this.qaElements.push(this.craeteQaElement('juices-bottles', [2, 13], 5))
    this.qaElements.push(this.craeteQaElement('juices-juices_box', [13, 14], 6))
    this.qaElements.push(this.craeteQaElement('juices-exit', [7, 16], 7))
    this.qaElements.push(this.craeteQaElement('main-door_gym', [16, 6], 7))
  }

  async create(data) {
    ocLog(window._getTestTime() + ' - MainScene create i tms-')
    increaseCountProgress()

    if (this.loadingFinished) {
      await this.showLoading()
    }

    try {
      this.grassStepsSoundPlayer = this.sound.add('grass-footsteps')
      this.grassStepsSoundPet = this.sound.add('grass-footsteps')
      this.woodStepsSoundPlayer = this.sound.add('wood-footsteps')
      this.woodStepsSoundPet = this.sound.add('wood-footsteps')
    } catch (stepsError) {
      console.error(stepsError)
    }

    ocLog(window._getTestTime() + ' - MainScene create 1.1 tms-')
    increaseCountProgress()

    this.isRankingShowed = getIsRankingShowed()

    ocLog(window._getTestTime() + ' - MainScene create 1.2 tms-')
    increaseCountProgress()
    this.unsubscriberRedux = store.subscribe(this.reduxListener.bind(this)) // TODO unsuscribe al matar escena
    ocLog(window._getTestTime() + ' - MainScene create 1.3 tms-')
    increaseCountProgress()
    let respawnUnitGuid = null
    let respawnUnitName = null
    let respawnTile = null

    if (this.isFirstMapLoad) {
      this.isFirstMapLoad = false
      let respawn = getRespawn()

      // Asegurar que existe la unidad de respawn
      if (respawn?.unit_guid) {
        const currentState = store.getState()
        const currentUnits = currentState.units
        const hasRespanwUnit = currentUnits.find(
          (cUnit) => cUnit.unit_guid === respawn.unit_guid
        )

        if (!hasRespanwUnit) {
          await updateRespawn(null)

          store.dispatch(
            resetRespawn({
              unitGuid: null,
              lessonGuid: null,
              unitExitTile: null
            })
          )

          respawn = null
        }
      }

      respawnUnitGuid = respawn?.unit_guid
      respawnUnitName = respawn?.unit_name
      respawnTile =
        respawn && respawn.column && respawn.column
          ? [respawn.column, respawn.row]
          : null

      if (respawnUnitGuid) {
        const enterLessonEvent = new CustomEvent('enter-lesson')
        window.dispatchEvent(enterLessonEvent)
        ocLog(window._getTestTime() + ' - MainScene create 1.4 tms-')
        increaseCountProgress()
        store.dispatch(
          enterUnit({
            action: 'ENTER_UNIT',
            unitGuid: respawnUnitGuid,
            unitName: respawnUnitName,
            unitExitTile: null
          })
        )

        Analysis.sendEvent(Analysis.EVENT.ENTER_UNIT, {
          unit_guid: respawnUnitGuid,
          unit_name: respawnUnitName
        })
      }
    }

    const metaberryState = store.getState().metaberry
    const unitGuid = respawnUnitGuid || metaberryState.unitGuid
    ocLog(window._getTestTime() + ' - MainScene create 2.1 tms-')
    increaseCountProgress()
    await this.createMap(unitGuid)
    ocLog(window._getTestTime() + ' - MainScene create 2.2 tms-')
    increaseCountProgress()
    const camera = this.cameras.main
    camera.roundPixels = true
    camera.setBounds(
      0,
      0,
      this.world.tileMap.widthInPixels + this.world.mapMarginX,
      this.world.tileMap.heightInPixels + this.world.mapMarginY
    )

    this.resize({ event: 'custom-onCreate' })

    this.createMiniMap()
    ocLog(window._getTestTime() + ' - MainScene create 3 tms-')
    const playerStartPoint = await this.createCharacter(
      metaberryState,
      camera,
      respawnTile
    )

    await this.createPlayerPet(playerStartPoint, camera)
    ocLog(window._getTestTime() + ' - MainScene create 4 tms-')
    this._createMouseEventListeners()

    // Set up the arrows to control the player
    this.cursors = this.input.keyboard.createCursorKeys()

    const joystickScene = this.scene.get('JoystickScene')
    if (joystickScene) {
      this.joystick = joystickScene.getJoystick()
      if (this.joystick) {
        this.joystickKeys = this.joystick.createCursorKeys()
      }
    }

    this.controls = new Phaser.Cameras.Controls.SmoothedKeyControl({
      camera: this.cameras.main,
      // zoomIn: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.Q),
      // zoomOut: this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.E),
      acceleration: 0.06,
      drag: 0.0005,
      maxSpeed: 1.0
    })
    ocLog(window._getTestTime() + ' - MainScene create 5 tms-')
    this.scale.on('resize', this.resize, this)

    this.createCompleted = true
    this.hideLoading()

    this.craeteQaAutationElements()

    window.localStorage.setItem('bb_game_initialized', 2)
    ocLog(window._getTestTime() + ' - MainScene create f tms-')
  }

  createMiniMap() {
    /* // miniMap WIP
    const maxSize = this.tileSize * 2
    let miniMapRatio = maxSize / this.world.tileMap.widthInPixels
    const miniMapRatioY = maxSize / this.world.tileMap.heightInPixels
    if (miniMapRatio > miniMapRatioY) miniMapRatio = miniMapRatioY
    const miniMapWidth = this.world.tileMap.widthInPixels * miniMapRatio

    const miniMapCamera = this.cameras.add(
      this.game.canvas.width - miniMapWidth - this.tileSize / 4, // x
      this.tileSize / 2,
      miniMapWidth,
      this.world.tileMap.heightInPixels * miniMapRatio
    )
    miniMapCamera.setBackgroundColor('rgba(0,0,0,0.5)')
    miniMapCamera.setOrigin(0)
    miniMapCamera.setAlpha(0.5)
    miniMapCamera.setZoom(miniMapRatio)
    // */
  }

  async createCharacter(metaberryState, camera, respawnTile) {
    const startPointTileCentered = {}
    const tileMap = this.world.tileMap
    let spawnTile = null

    if (metaberryState.action === 'EXIT_UNIT' || respawnTile) {
      spawnTile = respawnTile || metaberryState.unitExitTile

      // Si al salir de una unidad no hay 'unitExitTile' definida es que entro a la unidad desde inicio (sin portal)
      //  por lo que al salir de ella hay que buscar la tile de spawn que le corresponde
      if (!spawnTile) {
        const respawn = getRespawn()
        if (respawn && respawn.unit_guid) {
          spawnTile = this.portals.find(
            (portal) => portal.unit_guid === respawn.unit_guid
          ).spawn
          spawnTile = [spawnTile[0] + 1, spawnTile[1] + 1]
        }
      }
    }

    let isCharacterAtStart = true
    if (spawnTile) {
      // Se comprueba que la tile de inicio inidicada sea valida
      const isOutOfRange =
        spawnTile[0] - 1 < 0 ||
        spawnTile[1] - 1 < 0 ||
        spawnTile[0] - 1 >= tileMap.width ||
        spawnTile[1] - 1 >= tileMap.height

      if (!isOutOfRange) {
        const tileSolidId =
          this.world.finderGrid[spawnTile[1] - 1][spawnTile[0] - 1]
        const isOnSolid = this.world.walkableTiles.indexOf(tileSolidId) === -1

        if (!isOnSolid) {
          isCharacterAtStart = false

          startPointTileCentered.x =
            spawnTile[0] * this.tileSize - this.tileSize / 2

          startPointTileCentered.y =
            spawnTile[1] * this.tileSize - this.tileSize / 2
        }
      }
    }

    if (isCharacterAtStart) {
      const startPoint = tileMap.findObject(
        'interactives',
        (obj) => obj.name === 'start'
      )

      startPointTileCentered.x =
        (Math.floor(startPoint.x / tileMap.tileWidth) + 1) * this.tileSize -
        this.tileSize / 2

      startPointTileCentered.y =
        (Math.floor(startPoint.y / tileMap.tileHeight) + 1) * this.tileSize -
        this.tileSize / 2
    }

    this.player = new Character(
      this,
      'player',
      this.tileSize,
      true,
      startPointTileCentered,
      camera,
      this.world.mapMarginX,
      this.world.mapMarginY,
      false,
      this.grassStepsSoundPlayer,
      this.woodStepsSoundPlayer
    )

    const userAvatar = getUserAvatar()
    await this.player.generateCharacter(userAvatar.url, userAvatar.sprite_json)
    // await this.player.generateCharacter('./assets/avatares-2/Character_14/PNG/walking.png')

    return startPointTileCentered
  }

  async createPlayerPet(camera) {
    const currentPetAvatar = getPetAvatar()
    // ocLog('createPlayerPet - inside - currentPetAvatar', currentPetAvatar)

    if (currentPetAvatar) {
      const playerTile = this.getCharacterStandingTile(this.player)
      // ocLog('playerTile', playerTile)
      let playerPetTile = null
      for (let x = -1; x < 2; x++ && !playerPetTile) {
        for (let y = -1; y < 2; y++ && !playerPetTile) {
          if (x !== 0 || y !== 0) {
            if (
              this.world.isTileWalkable(playerTile[0] + x, playerTile[1] + y)
            ) {
              playerPetTile = [playerTile[0] + x, playerTile[1] + y]
            }
          }
        }
      }
      // ocLog('playerPetTile', playerPetTile)

      const startPoint = {
        x: playerPetTile[0] * this.tileSize - this.tileSize / 2,
        y: playerPetTile[1] * this.tileSize - this.tileSize / 2
      }

      this.playerPet = new Character(
        this,
        'playerPet',
        this.tileSize,
        true,
        { x: startPoint.x, y: startPoint.y },
        camera,
        this.world.mapMarginX,
        this.world.mapMarginY,
        true,
        this.grassStepsSoundPet,
        this.woodStepsSoundPet
      )

      const animationJson = currentPetAvatar.animation.spriteJson
      const animationParsedJson = animationJson
        ? JSON.parse(animationJson)
        : null

      await this.playerPet.generateCharacter(
        currentPetAvatar.animation.spriteImage,
        animationParsedJson
      )

      // const ptTile = this.getCharacterStandingTile(this.playerPet)
      // ocLog('ptTile', ptTile)

      return true
    }
  }

  getCameraZoomByWidthAndHeight(
    desiredWidth,
    desiredHeight,
    returnMax,
    returnUnfixed
  ) {
    const zoomX = this.game.canvas.width / desiredWidth
    const zoomY = this.game.canvas.height / desiredHeight
    const zoomMin = zoomX < zoomY ? zoomX : zoomY
    const zoomMax = zoomX > zoomY ? zoomX : zoomY

    let result = returnMax ? zoomMax : zoomMin

    // Dejar zoom en números que no generen decimales "desajustados", para evitar artefactos entre las tiles
    if (!returnUnfixed) {
      for (let z = 1, zMax = FIXED_ZOOMS.length; z < zMax; z++) {
        if (FIXED_ZOOMS[z] > result) {
          const diffUp = FIXED_ZOOMS[z] - result
          const diffDown = result - FIXED_ZOOMS[z - 1]

          result = diffUp < diffDown ? FIXED_ZOOMS[z] : FIXED_ZOOMS[z - 1]
          break
        }
      }
    }

    return result
  }

  setMainCameraInitialZoom() {
    const camera = this.cameras.main
    const tileMap = this?.world?.tileMap

    if (camera && tileMap) {
      // Zoom para que se vean un número mínimo de tiles en x o y, en el otro eje puede haber más (VISIBLE_TILES_ON_MAX_ZOOM)
      const visibleTiles = this.isCourseMap
        ? NEAR_ZOOM_COURSE_MAP_VISIBLE_TILES
        : NEAR_ZOOM_UNIT_MAP_VISIBLE_TILES

      const nearZoom = this.getCameraZoomByWidthAndHeight(
        (tileMap.widthInPixels / tileMap.width) * visibleTiles,
        (tileMap.heightInPixels / tileMap.height) * visibleTiles,
        false
      )

      // Zoom para que se vea el mapa con un eje (el que mejor quepa) ajustado a la "ventanta"
      const fitZoom = this.getCameraZoomByWidthAndHeight(
        tileMap.widthInPixels,
        tileMap.heightInPixels,
        true
      )

      // De los dos zooms anteriores nos quedamos con el de mejor visibilidad:
      // Si el ajustado es más pequeño quedaría demasiado cerca, por lo que se usa el otro
      this.cameraNearZoom = fitZoom < nearZoom ? nearZoom : fitZoom

      // Zoom para que se vea el mapa entero
      this.cameraFarZoom = this.getCameraZoomByWidthAndHeight(
        tileMap.widthInPixels,
        tileMap.heightInPixels,
        false,
        true
      )

      const initialZoom = this.cameraZoomedOut
        ? this.cameraFarZoom
        : this.cameraNearZoom

      camera.setZoom(initialZoom)
    }
  }

  birdeye() {
    if (this.cameras && this.cameras.main) {
      this.cameras.main.zoomTo(
        this.cameraZoomedOut ? this.cameraNearZoom : this.cameraFarZoom,
        1200,
        this.cameraZoomedOut
          ? Phaser.Math.Easing.Sine.In // Valores para el zoom al acercar la cámara
          : Phaser.Math.Easing.Sine.Out, // Valores para el zoom al alejar la cámara
        true,
        (_camera, _progress, _zoom) => {
          // Para que el centrado se ajuste al zoom durante los propios cambios de zoom
          this.adjustGameToCenter(_zoom)
        }
      )

      const zoomEventObject = {
        context: this.isCourseMap ? 'world' : 'unit',
        type: this.cameraZoomedOut ? 'zoom in' : 'zoom out'
      }
      const currentUnitGuid = store.getState().metaberry.unitGuid
      if (currentUnitGuid) {
        zoomEventObject.unit_id = currentUnitGuid
      }

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Map Zoomed Clicked'],
        zoomEventObject
      )

      this.cameraZoomedOut = !this.cameraZoomedOut
    }
  }

  adjustGameToCenter(customZoom, setBounds = true) {
    // Detener movimiento
    if (this.player) {
      this.player.setPath(null)
      this.player.setDestination(null)
    }

    // Modificar margenes para centrado de mapa
    const oldMarginX = this.world.mapMarginX
    const oldMarginY = this.world.mapMarginY

    //  - Mapa
    this.world.calculateMarginToCenterMap(customZoom)

    //  - Objetos (posicionados por el mapa: carteles, baterías...)
    for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
      const portal = this.portals[p]
      if (portal.type !== 'exit') {
        portal.information.updateMapMargins(
          this.world.mapMarginX,
          this.world.mapMarginY
        )
      }
    }

    if (this.exitIcon) {
      this.exitIcon.x -= oldMarginX - this.world.mapMarginX
      this.exitIcon.y -= oldMarginY - this.world.mapMarginY
    }

    //  - Personaje
    if (this.player) {
      this.player.updateMapMargins(this.world.mapMarginX, this.world.mapMarginY)
    }

    if (this.playerPet?.isCharacterDone) {
      this.playerPet.updateMapMargins(
        this.world.mapMarginX,
        this.world.mapMarginY
      )
    }

    //  - Límites para cámara
    if (setBounds === true) {
      this.cameras.main.setBounds(
        0,
        0,
        this.world.tileMap.widthInPixels + this.world.mapMarginX,
        this.world.tileMap.heightInPixels + this.world.mapMarginY
      )
    }
  }

  async resize(eventData) {
    this.setMainCameraInitialZoom()

    // Si no es el inicial (eventData es null en el rezize inicial)
    if (eventData) {
      this.adjustGameToCenter(this.cameras.main.zoom)
    }
  }

  async createMap(unitGuid) {
    ocLog(window._getTestTime() + ' - MainScene createMap i tms-')
    increaseCountProgress()
    this.isCourseMap = unitGuid === undefined || unitGuid === null

    // ---- LOCAL VS SERVER
    /* Mapas desde "Local"
    const mapEndPoint = unitGuid
      ? 'assets/maps/unit-0.json'
      : 'assets/maps/course2.json'
    // */
    //* Mapas desde Servidor
    const courseGuid = await getCourseGuid()
    const mapEndPoint = createEndPoint(
      !this.isCourseMap
        ? `lms/courses/${courseGuid}/blueberry/units/${unitGuid}/phaser`
        : `lms/courses/${courseGuid}/blueberry/phaser`
    )
    // */
    if (this.isCourseMap) {
      const currentState = store.getState()
      const programId = currentState.practice.programId
      const currentUnits = currentState.units
      // ocLog('currentUnits', currentUnits)
      let unitsLocked = null
      let unitsAvailables = null

      if (currentUnits && currentUnits.length && currentUnits.length > 0) {
        unitsLocked = 0
        unitsAvailables = 0

        for (let u = 0, uMax = currentUnits.length; u < uMax; u++) {
          if (currentUnits[u].is_available === 1) unitsAvailables++
          else unitsLocked++
        }
      }

      Analysis.sendSegmentTrackEvent(Analysis.SEGMENT_EVENTS['World Entered'], {
        program_id: programId,
        course_id: courseGuid,
        number_unit_locked: unitsLocked,
        number_unit_available: unitsAvailables
      })
    }

    const camera = this.cameras.main
    const backgroundColorString = !this.isCourseMap
      ? UNITS_BACKGRUIND_COLOR
      : COURSE_BACKGRUIND_COLOR
    const backgroundColor = Phaser.Display.Color.HexStringToColor(
      backgroundColorString
    ).color
    camera.setBackgroundColor(backgroundColor)

    this.world = new Map(this, mapEndPoint, 'assets/maps/tileSets/')

    ocLog(window._getTestTime() + ' - MainScene createMap 1 tms-')
    increaseCountProgress()
    await this.world.generateMap(camera.zoom)
    this.tileSize = this.world.tileMap.tileWidth
    ocLog(window._getTestTime() + ' - MainScene createMap 2 tms-')

    await this.createPortals(unitGuid)

    ocLog(window._getTestTime() + ' - MainScene createMap f tms-')

    return true
  }

  createPortalInformation(portal, tileSize, isPreviousLessonAvailable) {
    if (portal.information) {
      const frame = portal.information.split(',').map((item) => parseInt(item))

      if (portal.type === 'unit') {
        portal.information = new UnitInformation(
          this,
          tileSize,
          frame[0] * tileSize,
          frame[1] * tileSize,
          this.world.mapMarginX,
          this.world.mapMarginY,
          /* portal.index + 1 + ' - ' + */ portal.name,
          portal.status,
          portal.ranking,
          portal?.is_available === 1,
          this.isRankingShowed
        )

        // Añadir a la grid de búsqueda de caminos las patas del cartel
        this.world.updateFinderGridCell(frame[0], frame[1] + 1, false)
        this.world.updateFinderGridCell(frame[0] + 1, frame[1] + 1, false)
      } else {
        // Notifica al onboarding si hay baterías rotas
        const firstBrokenBatery = new CustomEvent('onboarding-broken-battery')
        if (portal.status === 'broked_start') {
          // ocLog('>>>>>>>> BROKEN BATTERY from start')
          window.dispatchEvent(firstBrokenBatery)
        }

        portal.batteryPosition = frame

        // ocLog('new LessonInformation')
        portal.information = new LessonInformation(
          this,
          frame[0] * tileSize,
          frame[1] * tileSize,
          this.world.mapMarginX,
          this.world.mapMarginY,
          tileSize,
          tileSize,
          portal.challenges,
          portal.status,
          portal?.is_available === 1,
          isPreviousLessonAvailable
        )
      }

      portal.information.generateInformation()
    }
  }

  async getUnitEventData(unitGuid, lessons) {
    const currentState = store.getState()
    const programId = currentState.practice.programId
    const courseId = await getCourseGuid()
    const unit = currentState.units.filter(
      (_unit) => _unit.unit_guid === unitGuid
    )
    const unitName = unit && unit[0] ? unit[0].unit_name : ''

    let lessonsInstall = 0
    let lessonsEmpty = 0
    let lessonsInit = 0
    let lessonsCharging = 0
    let lessonsComplete = 0
    let lessonsStart = 0
    let lessonsLocked = 0
    // let lessonsBrokedStart = 0

    for (let l = 0, lMax = lessons.length; l < lMax; l++) {
      if (lessons[l].is_available === 1) {
        switch (lessons[l].status) {
          case 'install':
            lessonsInstall++
            break

          case 'empty':
            lessonsEmpty++
            break

          case 'init':
            lessonsInit++
            break

          case 'charging':
            lessonsCharging++
            break

          case 'complete':
            lessonsComplete++
            break

          case 'start':
            lessonsStart++
            break

          /*
          case 'broked_start':
            lessonsBrokedStart++
            break
          */

          default:
            lessonsLocked++
        }
      } else {
        lessonsLocked++
      }
    }

    return {
      program_id: programId,
      course_id: courseId,
      unit_id: unitGuid,
      unit_name: unitName,
      // where_clicked:,
      number_lessons_locked: lessonsLocked,
      // number_lessons_broked_start: lessonsBrokedStart,
      number_lessons_install: lessonsInstall,
      number_lessons_empty: lessonsEmpty,
      number_lessons_init: lessonsInit,
      number_lessons_charging: lessonsCharging,
      number_lessons_complete: lessonsComplete,
      number_lessons_start: lessonsStart
    }
  }

  // A unidades o lecciones
  async createPortals(unitGuid) {
    // TODO: Repartir y avisar si no hay huecos suficientes en el mapa

    let lessons = null
    let isCourseMap = true

    if (unitGuid) {
      isCourseMap = false
      lessons = await getLessons(unitGuid)

      const unitEnteredEventObject = await this.getUnitEventData(
        unitGuid,
        lessons
      )

      const mapMillis = window.localStorage.getItem('bb_map_created')
      let timeSpentInMap = 0
      if (mapMillis) {
        timeSpentInMap = new Date().getTime() - parseInt(mapMillis)
      }

      unitEnteredEventObject.time_spent_in_pap = timeSpentInMap

      Analysis.sendEvent(Analysis.EVENT.ENTER_UNIT, unitEnteredEventObject)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Unit Entered'],
        unitEnteredEventObject
      )

      // TODO itentar centralizar la obtención y actualización de lecciones un poco más
      store.dispatch(
        initLessons({
          unit_guid: unitGuid,
          lessons: lessons
        })
      )
    }

    window.localStorage.setItem('bb_map_created', new Date().getTime())

    const portalType = isCourseMap ? 'unit' : 'lesson'
    const apiPortals = isCourseMap ? store.getState().units : lessons // Unidades o lecciones desde API y almacenadas
    const mapPortals = this.world.mapObjets.interactives.list // "Huecos" (objetos) en el mapa para desplegar las unidades o lecciones
      .filter((item) => item.name.toLowerCase() === portalType)
      .reverse()

    const tileSize = this.world.tileMap.tileWidth

    // Asigna las lecciones del curso a las del mapa (lessons api <= objects map)
    if (!mapPortals || mapPortals.length === 0) {
      console.error(
        'Error: There is no object on the map for ' +
          (isCourseMap ? 'unit' : 'lesson') +
          ' items'
      )
      errorRedirection(isCourseMap ? '/error-BBE-103' : '/error-BBE-104', true)
    }

    if (!apiPortals || apiPortals.length === 0) {
      console.error(
        'Error: There are no ' + (isCourseMap ? 'unit' : 'lesson') + ' items'
      )
      errorRedirection(isCourseMap ? '/error-BBE-101' : '/error-BBE-102', true)
    }

    if (mapPortals.length < apiPortals.length) {
      console.error(
        'Error: There are no enough objects on the map for the ' +
          (isCourseMap ? 'unit' : 'lessons') +
          ' items'
      )
      errorRedirection(isCourseMap ? '/error-BBE-105' : '/error-BBE-106', true)
    }

    // -
    this.portals = []

    for (let p = 0, pMax = apiPortals.length; p < pMax; p++) {
      const apiPortal = apiPortals[p]
      const mapPortal = apiPortal.order
        ? mapPortals.filter((item) => item.index === apiPortal.order - 1)[0]
        : mapPortals[p]

      const currentPortal = {
        ...apiPortal,
        ...mapPortal,
        type: portalType,
        name:
          portalType === 'unit' ? apiPortal.unit_name : apiPortal.lesson_name,
        index: p,
        active: false,
        uuid: uuidv4()
      }

      currentPortal.spawn = currentPortal.spawn
        .split(',')
        .map((item) => parseInt(item))

      if (currentPortal.information) {
        let isPreviousLessonAvailable = true
        if (portalType === 'lesson' && p > 0) {
          isPreviousLessonAvailable = apiPortals[p - 1]?.is_available === 1
        }

        this.createPortalInformation(
          currentPortal,
          tileSize,
          isPreviousLessonAvailable
        )
      }

      if (currentPortal.ellipse) {
        currentPortal.x += currentPortal.width / 2
        currentPortal.y += currentPortal.height / 2
      }

      this.portals.push(currentPortal)
    }

    const exitPortal = this.world.mapObjets.interactives.list.filter(
      (item) => item.name.toLowerCase() === 'exit'
    )[0]

    if (exitPortal) {
      const currentPortal = {
        ...exitPortal,
        type: 'exit',
        name: translate('exit'),
        active: false,
        uuid: uuidv4()
      }

      this.portals.push(currentPortal)
    }
  }

  tryAgainUpdateLessons() {
    if (this.tryAgainUpdateLessonsCounter < 3) {
      this.tryAgainUpdateLessonsCounter++
      setTimeout(() => {
        const currentState = store.getState()
        const currentGuid = currentState.metaberry.unitGuid
        if (currentGuid) {
          const currentUnit = currentState.units.find(
            ({ unit_guid }) => unit_guid === currentGuid
          )
          const currentLessons =
            currentUnit && currentUnit.lessons ? currentUnit.lessons : []
          this.updateLessonsPortals(currentLessons)
        } else {
          this.tryAgainUpdateLessons()
        }
      }, 150)
    } else {
      this.tryAgainUpdateLessonsCounter = 0
      window.location.reload()
    }
  }

  // A unidades o lecciones
  async updateLessonsPortals(lessons) {
    const firstUnlockedLesson = new CustomEvent('onboarding-lesson-unlocked')
    const firstBrokenBatery = new CustomEvent('onboarding-broken-battery')
    const firstUnlockedUnit = new CustomEvent('onboarding-unit-unlocked')
    const firstBateryComplete = new CustomEvent('onboarding-complete-battery')
    const lessonLevels = []
    let statusChangeDetected = false

    // ocLog('this.portals', this.portals)

    for (let i = 0, iMax = this.portals.length; i < iMax; i++) {
      const currentPortal = this.portals[i]
      // ocLog('currentPortal', currentPortal)
      // ocLog('lessonLevels', lessonLevels)
      if (currentPortal.type !== 'exit') {
        const apiPortal = lessons.find(
          ({ lesson_guid }) => lesson_guid === currentPortal.lesson_guid
        )

        // ESTO ES UN INTENTO DE CONTROL PARA EL ERROR
        //  Uncaught (in promise) TypeError: Cannot read properties of undefined (reading 'is_available')
        // MIENTRAS NO TENGAMOS INFORMACION MAS PRECISA O MANERA DE REPRODUCIRLO
        if (!apiPortal) {
          console.error('Error apiPortal undefined')
          this.tryAgainUpdateLessons()
          return
        } else {
          this.tryAgainUpdateLessonsCounter = 0
        }

        // Notifica al onboarding un cambio en la disponibilidad
        if (currentPortal.is_available !== apiPortal.is_available) {
          // ocLog('>>>>>>>> LESSON UNLOCKED')
          // window.dispatchEvent(firstBateryComplete)
          // window.dispatchEvent(firstUnlockedLesson)
          store.dispatch(setFirstUnlockedLessonPending(true))

          // Indica que objeto mover, pero no mueve camara (eso se hace desde el popup)
          store.dispatch(setMoveCameraToObject({ index: i }))
          // window.dispatchEvent(new CustomEvent('reward-battery-unlock'))
          store.dispatch(setRewardBatteryUnlockPending(true))
        }

        // Notifica al onboarding un cambio en el estado
        if (currentPortal.status !== apiPortal.status) {
          // ocLog('>>>>>>>> STATUS CHANGED')
          if (
            !statusChangeDetected &&
            apiPortal.status !== 'disabled' &&
            currentPortal.status !== 'disabled' &&
            apiPortal.status !== 'broked_start' &&
            currentPortal.status !== 'complete'
          ) {
            statusChangeDetected = true
          }

          if (apiPortal.status === 'complete') {
            // ocLog('>>>>>>>> BATTERY COMPLETE')
            updateBatteryPieces(
              JSON.stringify(apiPortal.pieces.have) ===
                JSON.stringify(apiPortal.pieces.pieces)
            )

            // window.dispatchEvent(firstBateryComplete)
            store.dispatch(setFirstBateryCompletePending(true))
          }

          if (apiPortal.status === 'broked_start') {
            // ocLog('>>>>>>>> BROKEN BATTERY')
            window.dispatchEvent(firstBrokenBatery)
            store.dispatch(setFirstBrokenBateryPending(true))
          }
        }

        currentPortal.is_available = apiPortal.is_available
        currentPortal.status = apiPortal.status
        currentPortal.challenges = apiPortal.challenges
        currentPortal.lessonchallenges = apiPortal.challenges
        currentPortal.lesson_level = apiPortal.lesson_level
        currentPortal.pieces = apiPortal.pieces

        lessonLevels.push(currentPortal.lesson_level)

        if (currentPortal.information) {
          let isPreviousLessonAvailable = true

          if (i > 0) {
            isPreviousLessonAvailable =
              lessons.find(
                ({ lesson_guid }) =>
                  lesson_guid === this.portals[i - 1].lesson_guid
              )?.is_available === 1
          }

          currentPortal.information.editLessonIcon(
            currentPortal.status,
            currentPortal?.is_available === 1,
            isPreviousLessonAvailable,
            this.world.mapMarginX,
            this.world.mapMarginY
          )
        }
      }
    }

    // if (lessonLevels.every((level) => level >= 2)) {
    //   window.dispatchEvent(firstUnlockedUnit)
    // }

    if (this.lessonPanel) {
      this.destroyLessonPanel()
      this.playerScreenPosition = null
    }

    // Reset Lesson Panels
    for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
      if (this.portals[p].active === true) {
        this.portals[p].active = false
      }
    }
  }

  // TODO mejora a poligonos
  isInsideRectangle(point, rectX, rectY, rectWidth, rectHeight) {
    const xAxis = point.x > rectX && point.x < rectX + rectWidth
    const yAxis = point.y > rectY && point.y < rectY + rectHeight

    return xAxis && yAxis
  }

  isInsideEllipse(point, ellipseX, ellipseY, ellipseWidth, ellipseHeight) {
    const radiusX = ellipseWidth / 2
    const radiusY = ellipseHeight / 2
    const normalized = { x: point.x - ellipseX, y: point.y - ellipseY }

    return (
      (normalized.x * normalized.x) / (radiusX * radiusX) +
        (normalized.y * normalized.y) / (radiusY * radiusY) <=
      1
    )
  }

  isInsidePolygon(point, polygon) {
    let isInside = false

    let minX = polygon[0].x + this.world.mapMarginX
    let maxX = polygon[0].x + this.world.mapMarginX
    let minY = polygon[0].y + this.world.mapMarginY
    let maxY = polygon[0].y + this.world.mapMarginY

    for (let n = 1; n < polygon.length; n++) {
      const q = polygon[n]
      minX = Math.min(q.x + this.world.mapMarginX, minX)
      maxX = Math.max(q.x + this.world.mapMarginX, maxX)
      minY = Math.min(q.y + this.world.mapMarginY, minY)
      maxY = Math.max(q.y + this.world.mapMarginY, maxY)
    }

    if (point.x < minX || point.x > maxX || point.y < minY || point.y > maxY)
      return false

    for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      if (
        polygon[i].y > point.y !== polygon[j].y > point.y &&
        point.x <
          ((polygon[j].x - polygon[i].x) * (point.y - polygon[i].y)) /
            (polygon[j].y - polygon[i].y) +
            polygon[i].x
      ) {
        isInside = !isInside
      }
    }

    return isInside
  }

  isInsideArea(point, portal) {
    if (portal.polygon) {
      return this.isInsidePolygon(point, portal.polygon)
    } else if (portal.ellipse)
      return this.isInsideEllipse(
        point,
        portal.x + this.world.mapMarginX,
        portal.y + this.world.mapMarginY,
        portal.width,
        portal.height
        // portal.rotation
      )
    else
      return this.isInsideRectangle(
        point,
        portal.x + this.world.mapMarginX,
        portal.y + this.world.mapMarginY,
        portal.width || 1,
        portal.height || 1
      )
  }

  convertGamePositionToScreenPosition(gameX, gameY) {
    const camera = this.cameras.main

    /*
    // Get world position
    const worldTransformMatrix = gameObject.getWorldTransformMatrix()
    const x = worldTransformMatrix.getX(0, 0)
    const y = worldTransformMatrix.getY(0, 0)

    // Convert world position into canvas pixel space
    const displayScale = camera.scaleManager.displayScale
    const cameraMatrix = camera.matrix
    const tx =
      cameraMatrix.getX(x - camera.scrollX, y - camera.scrollY) / displayScale.x
    const ty =
      cameraMatrix.getY(x - camera.scrollX, y - camera.scrollY) / displayScale.y
    const screenPosition = { x: Math.round(tx), y: Math.round(ty) }
    */

    // La de arriba funciona, pero esta más simple parece ir bien
    const screenPosition = {
      x: Math.round((gameX - camera.worldView.x) * camera.zoom),
      y: Math.round((gameY - camera.worldView.y) * camera.zoom)
    }

    return screenPosition
  }

  /* Indica si el personaje está en la parte izquierda del mapa para mostrar el desplegable al otro lado
  isLeftSide() {
    const mapColumns = this.world.tileMap.width
    const spritePosition = this.player.getCenter()
    const playerTileColumn =
      Math.floor((spritePosition.x - this.world.mapMarginX) / this.tileSize) + 1

    return mapColumns / 2 > playerTileColumn
  }
  */

  // Obtine recortes del juego de la unida/lección anterior para
  //  mostrar como pista en paneles de unidades/lecciones bloqueadas
  async getLockedPortalsPreviousImages() {
    this.lockedPortalsPreviousImages = []

    // Desvincular cámara
    const mainCamera = this.cameras.main
    const savedZoom = mainCamera.zoom
    mainCamera.removeBounds()
    mainCamera.stopFollow()
    mainCamera.setZoom(this.cameraFarZoom)
    mainCamera.setOrigin(0)

    // Ocultar personaje para que no salga en las capturas
    this.player.sprite.visible = false
    if (this.playerPet) {
      this.playerPet.sprite.visible = false
    }

    const areUnitPortals = this.portals.some((portal) => portal.type === 'unit')

    const snapWidthInTiles = areUnitPortals ? 9 : 3
    const snapHeightInTiles = areUnitPortals ? 8 : 3
    const areaModifierX = areUnitPortals ? 4 : 1
    const areaModifierY = areUnitPortals ? 6 : 1
    const snapWidth = Math.round(
      this.tileSize * snapWidthInTiles * this.cameraFarZoom
    )
    const snapHeight = Math.round(
      this.tileSize * snapHeightInTiles * this.cameraFarZoom
    )

    for (let p = this.portals.length - 1; p > 0; p--) {
      if (this.portals[p].type !== 'exit') {
        if (this.portals[p]?.is_available !== 1 || p === 1) {
          const portalToSnap = this.portals[p - 1]
          const topLeftTileAnchor = areUnitPortals
            ? portalToSnap.spawn.map((x) => parseInt(x))
            : portalToSnap.batteryPosition

          const areaX =
            topLeftTileAnchor[0] * this.tileSize -
            areaModifierX * this.tileSize +
            this.world.mapMarginX
          const areaY =
            topLeftTileAnchor[1] * this.tileSize -
            areaModifierY * this.tileSize +
            this.world.mapMarginY

          // Centrar camara auxiliar en el area deseada
          mainCamera.setScroll(areaX, areaY)

          // Obtener imagen como snapshot del area de la camara secundaria
          const areaImage = await new Promise((resolve, reject) => {
            this.game.renderer.snapshotArea(
              0,
              0,
              snapWidth,
              snapHeight,
              (image) => resolve(image.src)
            )
          })
          this.lockedPortalsPreviousImages.unshift(areaImage)
        } else {
          this.lockedPortalsPreviousImages.unshift(null)
        }
      }
    }

    // Esta corresponde al portal inicial que siempre está desbloqueado
    // Se usa en el onboarding la imagen real
    this.lockedPortalsPreviousImages.unshift(null)

    // Personaje visible de nuevo
    this.player.sprite.visible = true
    if (this.playerPet) {
      this.playerPet.sprite.visible = true
    }

    // Revincular cámara
    mainCamera.setOrigin(0.5)
    mainCamera.setZoom(savedZoom)

    this.adjustGameToCenter(savedZoom)
    this.player.setCameraFollowCharacter(mainCamera)

    // Se guarda la imagen del primer portal para usar en el onboarding
    store.dispatch(setFirstBuildingImage(this.lockedPortalsPreviousImages[1]))
  }
  /* Otra opción para obtener imágenes, igual que lo anterior pero usando una cámara secundaria
     pero cuidado con el zoom/tamaño camara porque es relativa a la principal parece
    ...
    const auxCamera = this.cameras.add(
      0,
      0,
      this.game.canvas.width / mainCamera.zoom,
      this.game.canvas.height / mainCamera.zoom
    )
    auxCamera.setZoom(this.cameraFarZoom)
    auxCamera.setOrigin(0)
    ...
          auxCamera.setScroll(areaX, areaY)
    ...
    auxCamera.destroy()
  }
  */

  // Obtener posición jugador en pantalla
  getPlayerScreenPosition(camera) {
    const playerTile = this.getCharacterStandingTile(this.player)

    let playerPosition = this.world.getTileWorldXY(
      playerTile[0],
      playerTile[1],
      camera
    )

    const halfTile = Math.round(this.tileSize / 2)
    playerPosition = this.convertGamePositionToScreenPosition(
      playerPosition.x + halfTile,
      playerPosition.y + halfTile
    )

    return playerPosition
  }

  getBatteryScreenPosition(portalBatteryPosition, camera) {
    let batteryPosition = this.world.getTileWorldXY(
      portalBatteryPosition[0],
      portalBatteryPosition[1],
      camera
    )
    batteryPosition = this.convertGamePositionToScreenPosition(
      batteryPosition.x,
      batteryPosition.y
    )

    return batteryPosition
  }

  showExitIcon(portal) {
    if (!this.exitIcon) {
      this.exitIcon = this.add
        .sprite(
          portal.x + portal.width / 2 + this.world.mapMarginX,
          portal.y + portal.height + this.world.mapMarginY,
          'exit'
        )
        .setOrigin(0.5, 0)
        .setDepth(9)
        .setDisplaySize(this.tileSize - 4, this.tileSize - 4)

      this.exitIcon.setInteractive({ cursor: 'pointer' })

      this.exitIcon.on('pointerover', (event) => {
        this.exitIcon.tint = 0x888888
      })

      this.exitIcon.on('pointerout', (event) => {
        this.exitIcon.tint = 0xffffff
      })

      this.exitIcon.on('pointerdown', (event) => {
        this.exitIcon.destroy()
        this.exitIcon = null
        setTimeout(() => {
          this.enterPortal()
        }, 250)
        // this.enterPortal()
      })
    }
  }

  createDomWrapper() {
    const wrapper = document.createElement('div')
    // wrapper.style.cssText = 'position:absolute;overflow:hidden;'
    return wrapper
  }

  destroyUnitPanel() {
    if (this.unitPanelWrapper && this.unitPanel) {
      try {
        unmountComponentAtNode(this.unitPanelWrapper)
        document.querySelector('body').removeChild(this.unitPanelWrapper)
      } catch (e) {}
      this.unitPanel = null
      this.unitPanelWrapper = null
    }
  }

  destroyLessonPanel() {
    if (this.lessonPanelWrapper && this.lessonPanel) {
      try {
        unmountComponentAtNode(this.lessonPanelWrapper)
        document.querySelector('body').removeChild(this.lessonPanelWrapper)
      } catch (e) {}
      this.lessonPanel = null
      this.lessonPanelWrapper = null
    }
  }

  destroyBackCourseMapButton() {
    if (this.backCourseMapButtonWrapper && this.backCourseMapButton) {
      try {
        unmountComponentAtNode(this.backCourseMapButtonWrapper)
        document
          .querySelector('body')
          .removeChild(this.backCourseMapButtonWrapper)
      } catch (e) {}
      this.backCourseMapButton = null
      this.backCourseMapButtonWrapper = null
    }
  }

  async openPortalPanel(portal, previousPortal, index) {
    const courseGuid = await getCourseGuid()
    const previousPortalImage =
      this.lockedPortalsPreviousImages.length > index
        ? this.lockedPortalsPreviousImages[index]
        : null

    if (portal.type === 'unit') {
      const mainCamera = this.cameras.main

      // Obtener posición jugador en pantalla
      const playerPosition = this.getPlayerScreenPosition(mainCamera)
      this.playerScreenPosition = playerPosition

      this.unitPanelWrapper = this.createDomWrapper()
      this.unitPanel = createReactElement(MetaUnitPanel, {
        isAvailable:
          portal?.is_available !== undefined ? portal.is_available : 0,
        isRankingShowed: this.isRankingShowed,
        handleEnterButton: () => {
          if (this.unitPanel) {
            this.destroyUnitPanel()
            this.enterPortal()
          }
        },
        unitName: portal.name,
        previousUnitImage: previousPortalImage,
        ranking: portal.ranking,
        userGuid: store.getState().metaberry.userGuid,
        unitGuid: portal.unit_guid,
        previousUnitGuid: this.portals[index - 1]
          ? this.portals[index - 1].unit_guid
          : null,
        previousUnitName: this.portals[index - 1]
          ? this.portals[index - 1].unit_name
          : null,
        handleWhereButton: () => {
          const tileAnchor = this.portals[index - 1].spawn.map((x) =>
            parseInt(x)
          )
          const anchorX = tileAnchor[0] * this.tileSize
          const anchorY = tileAnchor[1] * this.tileSize

          this.disableGame()
          this.cameraToObject(anchorX, anchorY, () => {
            setTimeout(() => {
              this.cameraToPlayer(this.enableGame.bind(this))
            }, 600)
          })
        },
        playerScreenPosition: playerPosition,
        currentTileSize: Math.round(this.tileSize * mainCamera.zoom),
        pathSchool: store.getState().configuration.pathSchool
      })
      renderReact(this.unitPanel, this.unitPanelWrapper)
      document.querySelector('body').appendChild(this.unitPanelWrapper)

      Analysis.sendSegmentTrackEvent(
        Analysis.SEGMENT_EVENTS['Unit Popup Viewed'],
        {
          program_id: store.getState().practice.programId,
          course_id: courseGuid,
          unit_guid: portal.unit_guid,
          unit_name: portal.name,
          unit_status: portal.status
        }
      )

      portal.active = true
      this.portalActive = portal
    }

    if (portal.type === 'lesson') {
      const metaberryState = store.getState().metaberry

      if (metaberryState.areLessonsUpdatedAfterPracticing) {
        const mainCamera = this.cameras.main

        // Obtener posición jugador en pantalla
        const playerPosition = this.getPlayerScreenPosition(mainCamera)
        this.playerScreenPosition = playerPosition

        // Obtener posición batería en pantalla
        const batteryPosition = this.getBatteryScreenPosition(
          portal.batteryPosition,
          mainCamera
        )

        // Crear panel informativo de lección
        this.lessonPanelWrapper = document.createElement('div')
        this.lessonPanel = createReactElement(MetaLessonPanel, {
          status: portal.status,
          isAvailable:
            portal?.is_available !== undefined ? portal.is_available : 0,
          unitGuid: portal.unit_guid,
          lessonGuid: portal.lesson_guid,
          lessonName: portal.lesson_name,
          lessonNumber: index + 1,
          level: portal.lesson_level,
          pieces: portal.pieces,
          previousLessonGuid: previousPortal
            ? previousPortal.lesson_guid
            : null,
          previousLessonName: previousPortal
            ? previousPortal.lesson_name
            : null,
          previousLessonImage: previousPortalImage,
          handleEnterButton: () => {
            if (this.lessonPanel) {
              this.destroyLessonPanel()
              this.playerScreenPosition = null
              setTimeout(() => {
                this.enterPortal()
              }, 200)
            }
          },
          playerScreenPosition: playerPosition,
          batteryScreenPosition: batteryPosition,
          currentTileSize: Math.round(this.tileSize * mainCamera.zoom)
        })
        renderReact(this.lessonPanel, this.lessonPanelWrapper)
        document.querySelector('body').appendChild(this.lessonPanelWrapper)

        const aloneMemberData = getAloneUserData()
        let playerGuid = null
        if (aloneMemberData) {
          playerGuid = aloneMemberData?.guid
        } else {
          const currentPlayerData = getFamilyUserData()
          playerGuid = currentPlayerData?.guid
        }

        Analysis.sendSegmentTrackEvent(
          Analysis.SEGMENT_EVENTS['Lesson Practice Popup Viewed'],
          {
            program_id: store.getState().practice.programId,
            course_id: courseGuid,
            unit_id: portal.unit_guid,
            lesson_guid: portal.lesson_guid,
            lesson_name: portal.name,
            lesson_status: portal.status,
            user_id: playerGuid
          }
        )

        portal.active = true
        this.portalActive = portal
      }
    }

    if (portal.type === 'exit') {
      this.showExitIcon(portal)

      portal.active = true
      this.portalActive = portal
    }
  }

  closePortalPanel(portal) {
    if (portal.type === 'unit') {
      if (this.unitPanel) {
        this.destroyUnitPanel()
      }
    }

    if (portal.type === 'lesson') {
      if (this.lessonPanel) {
        this.destroyLessonPanel()
      }
      this.playerScreenPosition = null
    }

    portal.active = false
    if (portal.uuid === this.portalActive.uuid) {
      this.portalActive = null
    }
  }

  checkPortals() {
    const spritePosition = this.player.getCenter()
    // Según posición del jugador:
    // Para evitar errores entre cierre y apertura de portales, lo he separado en 2 bucles en lugar de 1
    // - Cerrar portales
    for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
      const portal = this.portals[p]
      if (portal.active && !this.isInsideArea(spritePosition, portal)) {
        this.closePortalPanel(portal)

        if (portal.type === 'exit' && this.exitIcon) {
          this.exitIcon.destroy()
          this.exitIcon = null
        }
      }
    }

    // - Abrir portales
    if (!this.isLoading) {
      for (let p = 0, pMax = this.portals.length; p < pMax; p++) {
        const portal = this.portals[p]
        const previousPortal = p > 0 ? this.portals[p - 1] : undefined

        if (!portal.active && this.isInsideArea(spritePosition, portal)) {
          this.openPortalPanel(portal, previousPortal, p)
        }
      }
    }
  }

  enterPortalByKey() {
    if (this.portalActive) {
      if (this.unitPanel && this.portalActive?.is_available === 1) {
        this.destroyUnitPanel()
        this.enterPortal()
      }

      if (this.lessonPanel && this.portalActive?.is_available === 1) {
        const metaberryState = store.getState().metaberry
        if (metaberryState.areLessonsUpdatedAfterPracticing) {
          this.destroyLessonPanel()
          this.playerScreenPosition = null
          this.enterPortal()
        }
      }

      /*
      if (this.exitPanel) {
        this.exitPanel.destroy()
        this.exitPanel = null
        this.enterPortal()
      }
      */
      if (this.portalActive.type === 'exit') {
        if (this.exitIcon) {
          this.exitIcon.destroy()
          this.exitIcon = null
        }

        this.enterPortal()
      }
    }
  }

  arePlayerAndPetCreated() {
    return this.player?.isCharacterDone && this.playerPet?.isCharacterDone
  }

  async followPlayer() {
    const playerStandingTile = this.getCharacterStandingTile(this.player)
    const playerPetStandingTile = this.getCharacterStandingTile(this.playerPet)
    const calculatedPath = await this.world.calculateBestPath(
      playerPetStandingTile[0],
      playerPetStandingTile[1],
      playerStandingTile[0],
      playerStandingTile[1]
    )
    if (calculatedPath?.length) {
      calculatedPath.pop()
    }
    this.playerPet.setPath(calculatedPath)
  }

  updatePetFollowPlayer(time, delta, directionsPressed) {
    if (this.arePlayerAndPetCreated()) {
      this.playerPet.update(
        time,
        delta,
        undefined,
        this.player.isMoving(directionsPressed)
      )

      if (this.player.isMoving(directionsPressed)) {
        if (!this.petFollowScheduled) {
          this.petFollowScheduled = true
          setTimeout(async () => {
            if (this.arePlayerAndPetCreated()) {
              this.followPlayer()
            }
            this.petFollowScheduled = false
          }, 200)
        }
      } else if (!this.playerPet.isMoving(directionsPressed)) {
        setTimeout(async () => {
          if (this.arePlayerAndPetCreated()) {
            const playerStandingTile = this.getCharacterStandingTile(
              this.player
            )
            const playerPetStandingTile = this.getCharacterStandingTile(
              this.playerPet
            )

            if (
              playerStandingTile[0] === playerPetStandingTile[0] &&
              playerStandingTile[1] === playerPetStandingTile[1]
            ) {
              // La mascota está encima del jugador, moverla a una tile adyacente
              const calculatedPath = await this.world.calculateBestPath(
                playerPetStandingTile[0],
                playerPetStandingTile[1],
                playerPetStandingTile[0] + 1,
                playerPetStandingTile[1] + 1
              )
              this.playerPet.setPath(calculatedPath)
            } else {
              const petPlayerDx =
                playerPetStandingTile[0] - playerStandingTile[0]
              const petPlayerDy =
                playerPetStandingTile[1] - playerStandingTile[1]
              const petDistanceToPlayer = Math.sqrt(
                petPlayerDx * petPlayerDx + petPlayerDy * petPlayerDy
              )

              // La mascota está demasiado lejos del jugador, hacer que lo siga
              if (petDistanceToPlayer > 2) {
                this.followPlayer()
              }
            }
          }
        }, 200)
      }
    }
  }

  updateCharactersMovement(time, delta) {
    const directionsPressed = { ...this.cursorPressed }
    let joystickForce = null

    if (this.joystickKeys) {
      joystickForce = {
        forceX: this.joystick.forceX,
        forceY: this.joystick.forceY,
        baseWidth: this.joystick.base.width,
        baseHeight: this.joystick.base.height
      }

      directionsPressed.up = directionsPressed.up || this.joystickKeys.up.isDown
      directionsPressed.down =
        directionsPressed.down || this.joystickKeys.down.isDown
      directionsPressed.left =
        directionsPressed.left || this.joystickKeys.left.isDown
      directionsPressed.right =
        directionsPressed.right || this.joystickKeys.right.isDown
    }

    if (this.player?.isCharacterDone) {
      this.player.update(time, delta, directionsPressed, joystickForce)
      this.updatePetFollowPlayer(time, delta, directionsPressed)
    }
  }

  update(time, delta) {
    if (this.createCompleted) {
      this.updateCharactersMovement(time, delta)

      // Acumular delta time
      this.accumulatedDelta += delta

      if (this.accumulatedDelta >= UPDATE_THRESHOLD) {
        this.accumulatedDelta = 0

        this.controls.update(delta)

        if (this.player?.isCharacterDone) {
          this.checkPortals()

          if ((this.unitPanel || this.lessonPanel) && !this.cameraMoving) {
            const mainCamera = this.cameras.main
            const playerPosition = this.getPlayerScreenPosition(mainCamera)

            const batteryPosition =
              this.lessonPanel &&
              this?.portalActive?.batteryPosition &&
              mainCamera
                ? this.getBatteryScreenPosition(
                    this.portalActive.batteryPosition,
                    mainCamera
                  )
                : null

            if (
              this.playerScreenPosition &&
              (this.playerScreenPosition.x !== playerPosition.x ||
                this.playerScreenPosition.y !== playerPosition.y)
            ) {
              clearTimeout(this.debounceUpdatePanel)
              this.debounceUpdatePanel = setTimeout(() => {
                const playerPositionEvent = new CustomEvent('player-moved', {
                  detail: {
                    playerPosition: playerPosition,
                    batteryPosition: batteryPosition,
                    currentTileSize: Math.round(this.tileSize * mainCamera.zoom)
                  }
                })
                document.dispatchEvent(playerPositionEvent)
              }, 200)
            }
            this.playerScreenPosition = playerPosition
          }
        }
      }
    }

    // El proceso de "fade in" se realiza al finalizar el update y en un 'timemout'
    //  para asegurar que se ha cargado y ajustado todo (mapa, personaje, camara...)
    if (this.loadingJustFinished) {
      window.dispatchEvent(new CustomEvent('loading-finished'))
      ocLog(
        window._getTestTime() + ' - MainScene update loadinjustfinished i tms-'
      )
      increaseCountProgress()

      this.loadingJustFinished = false

      setTimeout(async () => {
        ocLog(
          window._getTestTime() +
            ' - MainScene update loadinjustfinished 1 tms-'
        )
        increaseCountProgress()

        await this.getLockedPortalsPreviousImages()

        this.fadeScene(
          false,
          () => store.dispatch(setIsPhaserVisible(true)),
          () => {
            store.dispatch(setIsGameUIVisible(true))

            // Añade botón de volver a mapa principal
            if (!this.isCourseMap) {
              this.backCourseMapButtonWrapper = document.createElement('div')
              this.backCourseMapButton = createReactElement(
                MapCourseBackButton,
                {
                  handleClick: () => {
                    this.enterPortal(true)
                  }
                }
              )
              renderReact(
                this.backCourseMapButton,
                this.backCourseMapButtonWrapper
              )
              document
                .querySelector('body')
                .appendChild(this.backCourseMapButtonWrapper)
            }
            this.isLoading = false
            ocLog(
              window._getTestTime() +
                ' - MainScene update loadinjustfinished e tms-'
            )
            increaseCountProgress(true)

            window.dispatchEvent(new CustomEvent('game-started'))
          }
        )
      }, 150)
    }
  }
}
