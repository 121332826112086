import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import translate from '../../../i18n/translate'
import MissionDailyRewardCard from './components/MissionDailyRewardCard/MissionDailyRewardCard'
import useSound from '../../views/MetaberryView/hooks/useSound'
import { getMissionDailyReward } from '../../../services/rewardsService'
import { store } from '../../../store/configureStore'
import { setCoinsAccumulated } from '../../../store/RewardsSlice/RewardsSlice'
import { setDailyGoalProgress } from '../../../store/DailyGoalSlice/DailyGoalSlice'
import { Analysis } from '../../../services/analysisService'
import { ReactComponent as CoinSvg } from '../../../assets/img/metaberry_ui/coin.svg'

const MetaTabMissions = () => {
  const [isLoading, setIsLoading] = useState(true)
  const coinsAccumulated = useSelector((s) => s.rewards.coinsAccumulated)
  const { renderSoundFXController, playSoundEffect } = useSound()

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Missions Page Viewed']
    )

    setIsLoading(false)
  }, [])

  const handleGetDailyReward = () => {
    setTimeout(async () => {
      const dailyGoalState = store.getState().dailyGoal

      const missionDailyRewardResponse = await getMissionDailyReward(
        dailyGoalState.sessionId,
        dailyGoalState.sessionAttempId
      )

      if (missionDailyRewardResponse?.coins_accumulated) {
        Analysis.sendSegmentTrackEvent(
          Analysis.SEGMENT_EVENTS['Daily Reward Collected'],
          {
            coins_earned: 1,
            coins_acumulated: missionDailyRewardResponse?.coins_accumulated
          }
        )

        store.dispatch(
          setCoinsAccumulated(missionDailyRewardResponse?.coins_accumulated)
        )

        // Actualizar los valores que cambian para 'daily reward'
        store.dispatch(
          setDailyGoalProgress({
            progress: dailyGoalState.progress,
            totalExp: dailyGoalState.totalExp,
            sessionId: dailyGoalState.sessionId,
            sessionAttempId: dailyGoalState.sessionAttempId,
            canGetDailyReward: false,
            nextDailyReward: missionDailyRewardResponse?.next_daily_coins_reward
          })
        )
      }
    }, 1)
  }

  return (
    <div className="meta-popup-selector">
      {renderSoundFXController()}
      <div className="meta-popup-selector__header">
        <div className="meta-popup-selector__header-top">
          <div className="meta-popup-selector__header-left">
            <div
              className={`meta-popup-selector__header-left__content
                'meta-popup-selector__header-left__content-big'
              }`}
            >
              <div className="meta-popup-selector__header-left__content-coins">
                <div className="meta-popup-selector__header-left__content-coins-text__misions">
                  {coinsAccumulated}
                </div>
                <div className="meta-popup-selector__header-left__content-coins-icon">
                  <CoinSvg />
                </div>
              </div>
            </div>
          </div>

          <div className="meta-popup-selector__header-center">
            <div className="meta-popup-selector__title">
              {translate('missions_title')}
            </div>
          </div>

          <div className="meta-popup-selector__header-right">
            <div className="meta-popup-selector__header-right__content-empty"></div>
          </div>
        </div>
      </div>
      <div className="-meta-tab-missions__content">
        {!isLoading && (
          <MissionDailyRewardCard
            onClickGetReward={() => {
              playSoundEffect('collectCoins')
              handleGetDailyReward()
            }}
          />
        )}
      </div>
    </div>
  )
}

export default MetaTabMissions
