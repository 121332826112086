const MINIMUN_SIZE_IN_PIXELS = 600

/*
export default function isMobile() {
  // User agent data
  if (navigator?.userAgentData?.mobile) return true

  // User agent
  const userAgent =
    typeof navigator?.userAgent === 'string'
      ? navigator.userAgent.toLowerCase()
      : 'unknown'
  if (/mobile|tablet|android|iphone|ipad/gim.test(userAgent)) return true

  // Touch
  if (navigator?.maxTouchPoints && navigator.maxTouchPoints > 0) return true
  if (navigator?.msMaxTouchPoints && navigator.msMaxTouchPoints > 0) return true

  // Pointer coarse
  const matchMediaCoarse = window?.matchMedia
    ? window.matchMedia('(pointer:coarse)')
    : null
  if (
    matchMediaCoarse &&
    matchMediaCoarse.media === '(pointer:coarse)' &&
    !!matchMediaCoarse.matches
  ) {
    return true
  }

  // Size
  const screenSideA = window.screen.width
  const screenSideB = window.screen.height
  if (
    screenSideA <= MINIMUN_SIDE_IN_PIXELS ||
    screenSideB <= MINIMUN_SIDE_IN_PIXELS
  ) {
    return true
  }

  return false
}

export const isPhone = (minimunSortSideInPixels = 768) => {
  if (isMobile()) {
    // Si un lado (por si está girado) es menor que el mínimo indicado se considera smartphone
    // const screenSideA = window.screen.width
    // const screenSideB = window.screen.height
    const screenSideA = window.screen.width * window.devicePixelRatio
    const screenSideB = window.screen.height * window.devicePixelRatio

    if (
      screenSideA < minimunSortSideInPixels ||
      screenSideB < minimunSortSideInPixels
    ) {
      return true
    }
  }

  return false
}

export const isTablet = (minimunSortSideInPixels = 768) => {
  if (isMobile()) {
    // Si ambos lados (por si está girado) son mayores o iguales al mínimo indicado se considera tablet
    // const screenSideA = window.screen.width
    // const screenSideB = window.screen.height
    const screenSideA = window.screen.width * window.devicePixelRatio
    const screenSideB = window.screen.height * window.devicePixelRatio

    if (
      screenSideA >= minimunSortSideInPixels &&
      screenSideB >= minimunSortSideInPixels
    ) {
      return true
    }
  }

  return false
}
*/

export const userDeviceInformation = () => {
  const info = window.navigator
  const usefulInfo = {}
  usefulInfo.userAgent = info?.userAgent
  usefulInfo.connectionType = info?.connection?.effectiveType
  usefulInfo.connectionRtt = info?.connection?.rtt
  usefulInfo.language = info?.language
  usefulInfo.languages = info?.languages && info?.languages.join(', ')
  usefulInfo.maxTouchPoints = info?.maxTouchPoints
  usefulInfo.platform =
    info?.userAgentData?.platform || navigator?.platform || 'unknown'
  usefulInfo.product = info?.product
  usefulInfo.userAgent = info?.userAgent
  usefulInfo.userAgentData = info?.userAgentData
  usefulInfo.vendor = info?.vendor

  return usefulInfo
}

export const isLargerThanMinimunSize = (
  minimunSize = MINIMUN_SIZE_IN_PIXELS
) => {
  const screenWidth = window.innerWidth // SIN devicePixelRatio es espacio para "dibujar", CON son pixeles reales
  const screenHeight = window.innerHeight // SIN devicePixelRatio es espacio para "dibujar", CON son pixeles reales

  if (screenWidth < minimunSize || screenHeight < minimunSize) return false

  return true
}

export const isDeviceSupported = () => {
  const deviceType = getDeviceType()
  // ocLog('deviceType', deviceType)
  // ocLog('isLargerThanMinimunSize()', isLargerThanMinimunSize())

  switch (deviceType) {
    case 'phone':
      return false

    default: {
      if (!isLargerThanMinimunSize()) return false
    }
  }

  return true
}

export const getDeviceType = () => {
  const ua = navigator.userAgent.toLowerCase()
  let deviceType = 'desktop'

  // Las tablets iPad pueden usar como user agent el de un mac por eso añadimos este punto
  if (isIosDevice()) {
    if (ua.includes('iphone')) {
      deviceType = 'phone'
    } else {
      deviceType = 'tablet'
    }
  }
  // Si no es un dispositivo iOS se usa el método standar
  else if (
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      ua
    )
  ) {
    deviceType = 'tablet'
  } else if (
    /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(
      ua
    )
  ) {
    deviceType = 'phone'
  }

  return deviceType
}

export const isIosDevice = () => {
  // El user agent se fuerza porque a veces los iPad tiene userAgent de Mac
  //  se añade la comprobación adicional de si es táctil
  //  (mejor algún falso positivo que muchos falsos negativos en este caso)
  const userAgent = navigator.userAgent.toLowerCase()
  const isIOSUserAgent = /ipad|iphone|mac\sos/i.test(userAgent)
  const isTouchDevice = navigator.maxTouchPoints > 1

  return isIOSUserAgent && isTouchDevice
}

export const isAndroidDevice = () => {
  const userAgent = navigator.userAgent.toLowerCase()
  return /android/i.test(userAgent)
}

/* 
let unsupportedWindowResizeTimeout = null
// ScreenOrientation.addEventListener('change', (event) => {
window.addEventListener('resize', (event) => {
  clearTimeout(unsupportedWindowResizeTimeout)

  unsupportedWindowResizeTimeout = setTimeout(async () => {
    ocLog('resize')
    if (!isDeviceSupported()) {
      ocLog('resize NO')
      window.location.href = `${window.location.protocol}//${window.location.host}/unsopported`
    } else {
      ocLog(
        'resize YES',
        `${window.location.protocol}//${window.location.host}/game`
      )
      window.location.href = `${window.location.protocol}//${window.location.host}/game`
    }
  }, 300)
})
// */
