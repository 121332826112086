import React, { useState, useEffect } from 'react'
import './selectRegion.scss'
import iconFlagEu from '../../../../assets/icons/flags/eu-flag.svg'
import iconFlagAs from '../../../../assets/icons/flags/as-flag.svg'
import iconFlagMx from '../../../../assets/icons/flags/mx-flag.svg'
import iconFlagBr from '../../../../assets/icons/flags/br-flag.svg'
import iconFlagRd from '../../../../assets/icons/flags/rd-flag.svg'
import iconArrow from '../../../../assets/icons/arrows-up-down.svg'
import translate from '../../../../i18n/translate'
import apiService from '../../../../services/util/apiService'

const SelectRegion = () => {
  /* const domain = 'school.blueberrymath.com'
  const fullDomain = 'https://school.blueberrymath.com'
  const regionUser = {
    country: 'PR ',
    countryName: 'Spain',
    region: 'CL',
    regionName: 'Castille and Le%C3%B3n',
    city: 'Zamora'
  } */
  const [isOpen, setIsOpen] = useState(false)
  const [selectedRegionData, setSelectedRegionData] = useState(null) // Nuevo estado para selectedRegionData
  const regionsData = [
    // Cambié el nombre de la variable a regionsData
    {
      value: 'eu',
      label: translate('select_region_option_eu'),
      flag: iconFlagEu,
      endsWith: '.ai'
    },
    {
      value: 'as',
      label: translate('select_region_option_as'),
      flag: iconFlagAs,
      endsWith: '.com'
    },
    {
      value: 'do',
      label: translate('select_region_option_rd'),
      flag: iconFlagRd,
      endsWith: '.com'
    },
    {
      value: 'br',
      label: translate('select_region_option_br'),
      flag: iconFlagBr,
      endsWith: '.com'
    },
    {
      value: 'mx',
      label: translate('select_region_option_mx'),
      flag: iconFlagMx,
      endsWith: '.com.mx'
    }
  ]

  const [regions, setRegions] = useState(regionsData) // Nuevo estado para regions

  useEffect(() => {
    setRegions(regionsData) // Establecer el estado de regions

    const domain = window.location.hostname
    let match = []
    if (domain.includes('localhost')) {
      match.push(regionsData[0])
    } else {
      match = regionsData.filter((r) => domain.endsWith(r.endsWith))
    }
    if (match && match.length > 1) {
      // Si se encuentra más de un resultado es que estamos en un dominio compartido entre varios paises
      // Y tenemos que localizar al usuario para saber que opción le ponemos
      getRegionUser()
    } else {
      const region = match ? match[0] : regionsData[0]
      setSelectedRegionData(region)
    }
  }, [])

  const handleChange = (selectedRegion) => {
    const newRegion = regions.find((r) => r.value === selectedRegion)
    setSelectedRegionData(newRegion)
    let url = ''

    switch (selectedRegion) {
      case 'eu':
        url = 'https://school.blueberrymath.ai'
        break
      case 'as':
      case 'do':
      case 'br':
        url = 'https://school.blueberrymath.com'
        break
      case 'mx':
        url = 'https://school.blueberrymath.com.mx'
        break
      default:
        break
    }
    // Si el actual usuario tiene como dominio al mismo que tiene que ir no se redirige se le cambia solo el selector
    const fullDomain = window.location.protocol + '//' + window.location.host
    if (url && fullDomain !== url) {
      window.location.href = url
    }
    setIsOpen(false)
  }

  const getRegionUser = async () => {
    try {
      const regionUser = await apiService.fetchGlobal(
        'https://geo.blueberrymath.ai/country-guesser/guess'
      )

      let match = null
      switch (regionUser?.country) {
        case 'BR':
          match = regions.find((r) => r.value === 'br')
          break

        case 'DO':
          match = regions.find((r) => r.value === 'do')
          break

        default:
          match = regions.find((r) => r.value === 'as')
          break
      }
      setSelectedRegionData(match)
    } catch (error) {
      console.error('getRegionUser error-- ', error)
    }
  }

  return (
    <div className="region-select-container">
      <label className="region-select-label">
        {translate('select_region_label')}
      </label>
      <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
        <div className="selected-option">
          <img src={iconArrow} alt="" className="arrowIcon" />
          {selectedRegionData ? (
            <>
              <img
                src={selectedRegionData.flag}
                alt={selectedRegionData.label}
                className="flag"
              />
              {selectedRegionData.label}
            </>
          ) : (
            ''
          )}
        </div>
        {isOpen && (
          <ul className="options-list">
            {regions.map((region) => (
              <li
                key={region.value}
                onClick={() => handleChange(region.value)}
                className="option"
              >
                <img src={region.flag} alt={region.label} className="flag" />
                {region.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  )
}

export default SelectRegion
