import React, { useEffect } from 'react'
import { ReactComponent as MessageFrameSvg } from '../../../assets/img/metaberry_ui/information-frame.svg'
import { ReactComponent as StarsBackgroundSvg } from '../../../assets/img/metaberry_ui/daily_goal_bg.svg'
import { ReactComponent as WhiteStarsBackgroundSvg } from '../../../assets/img/metaberry_ui/white-stars.svg'
import { ReactComponent as ShieldSvg } from '../../../assets/img/metaberry_ui/level_shield.svg'
import { ReactComponent as ShadowSvg } from '../../../assets/img/metaberry_ui/shadow.svg'
import { ReactComponent as CoinsSvg } from '../../../assets/img/metaberry_ui/coins.svg'
import { ReactComponent as RibbonSvg } from '../../../assets/img/metaberry_ui/ribbon_blue.svg'
import { ReactComponent as CloseSvg } from '../../../assets/icons/icon_xclose.svg'
import useSound from '../../views/MetaberryView/hooks/useSound'
import './informationPopup.scss'
import PracticeReward from '../../../pages/PracticeReward/PracticeReward'

const CoinsGroup = ({ coins }) => {
  return (
    <div className="information-conins-group">
      <StarsBackgroundSvg className="information-coins-group__background" />
      <CoinsSvg className="information-coins-group__coins-image" />
      <RibbonSvg className="information-coins-group__ribbon-image" />
      <div className="information-coins-group__coins-number">{'+' + coins}</div>
    </div>
  )
}

const StarsBackgroundComponents = {
  levelUp: '',
  generalPopup: StarsBackgroundSvg,
  endOfChallenge: WhiteStarsBackgroundSvg
}

const LevelGroup = ({ children, context }) => {
  const StarsBackgroundSvg = StarsBackgroundComponents[context]
  return (
    <div className="information-level-group">
      <StarsBackgroundSvg className="information-level-group__background" />
      {children}
      <ShadowSvg
        preserveAspectRatio="none"
        className="information-level-group__shadow"
      />
    </div>
  )
}

const InformationPopup = ({
  message,
  level,
  coins,
  closeAction,
  buttonText,
  buttonAction,
  showCloseIcon,
  practiceProgressData,
  setPracticeBaseData,
  earnedPiecesAux,
  earnedCoinsAux,
  earnedExperienceAux,
  isChallengeEnd,
  batteryIcon,
  completeTitle,
  className
}) => {
  const { renderSoundFXController, playSoundEffect } = useSound()

  useEffect(() => {
    if (level) {
      playSoundEffect('levelUp')
    }
  }, [])

  const handleClickExterior = (event) => {
    if (closeAction && event.target === event.currentTarget) {
      closeAction && closeAction()
    }
  }

  return (
    <>
      {!isChallengeEnd && (
        <div
          className="information-popup__backdrop"
          onClick={closeAction}
        ></div>
      )}

      <div
        className={`information-popup ${className}`}
        onClick={handleClickExterior}
      >
        {renderSoundFXController()}
        {showCloseIcon && (
          <div
            className="information-popup_close_wrapper"
            onClick={closeAction}
          >
            <CloseSvg className="information-popup_close_image" />
          </div>
        )}
        {level && (
          <LevelGroup context="generalPopup">
            <>
              <ShieldSvg className="information-level-group__shield" />
              <div className="information-level-group__level">{level}</div>
            </>
          </LevelGroup>
        )}

        {batteryIcon && (
          <LevelGroup context="endOfChallenge">
            <div className="information-level-group__batteryIcon">
              {batteryIcon}
            </div>
          </LevelGroup>
        )}

        {coins && <CoinsGroup coins={coins} />}
        <div className="information-popup_message-wrapper">
          <MessageFrameSvg
            preserveAspectRatio="none"
            className="information-popup_message-frame"
          />
          {level && (
            <div
              className="information-popup_message-text"
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
          )}

          {batteryIcon && (
            <div className="information-popup_message-text_rewards">
              {completeTitle}
            </div>
          )}

          {coins && (
            <div
              className="information-popup_message-text"
              dangerouslySetInnerHTML={{ __html: message }}
            ></div>
          )}
        </div>
        {isChallengeEnd && (
          <PracticeReward
            practiceProgressData={practiceProgressData}
            earnedPiecesAux={earnedPiecesAux}
            earnedCoinsAux={earnedCoinsAux}
            earnedExperienceAux={earnedExperienceAux}
            setPracticeBaseData={setPracticeBaseData}
          />
        )}

        {buttonText && (
          <div className="information-popup_button" onClick={buttonAction}>
            {buttonText}
          </div>
        )}
      </div>
    </>
  )
}

export default InformationPopup
