/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ActivityHeader from '../../components/ActivityHeader/ActivityHeader'
import AbortPractice from '../../components/AbortPractice/AbortPractice'
import ModalLemonadeOnboarding from '../../../../../modules/modals/ModalLemonadeOnboarding/ModalLemonadeOnboarding'
import translate from '../../../../../i18n/translate'
import IssueReport from '../../components/IssueReport/IssueReport'
import Toast from '../../components/Toast/Toast'
import ActivityLoading from '../../components/ActivityLoading/ActivityLoading'
import ActivityBlueberryComponent from '../../../../../_practice-components/pages/ActivityBlueberryComponent/ActivityBlueberryComponent'
import { ACTIVITY_TYPES } from '../../../../../_practice-components/constants/ActivityConstants'
import { usePracticeView } from './usePracticeView'
import './practiceView.scss'
import { Analysis } from '../../../../../services/analysisService'
import useSound from '../../../../../modules/views/MetaberryView/hooks/useSound'
import { getCurrentLangString } from '../../../../../i18n/initI18n'
import InformationPopup from '../../../../../modules/atoms/InformationPopup/InformationPopup'
import DIFFICULT_ACTIVITY_COINS_REWARD from '../../../../../config/constants/difficultActivity'
import ModalSimple from '../../../../../modules/modals/ModalSimple/ModalSimple'

const PracticeView = ({
  practiceBaseData,
  setPracticeBaseData,
  practiceProgressData,
  setPracticeProgressData,
  practiceState,
  setPracticeState,
  messageToReactNative,
  isIphone
}) => {
  const { renderSoundFXController, playSoundEffect } = useSound()

  const isDevMode = useSelector((state) => state?.configuration?.isDevMode)

  const practiceViewRef = useRef(null)

  const {
    isBeforeFirstExercise,
    getPracticeStartExercise,
    experienceTotal,
    experienceNormal,
    experienceExtra,
    setShowAbortPracticePopup,
    currentExercise,
    previousSeedId,
    numberExercisesCorrect,
    numberExercisesIncorrect,
    setExperienceTotal,
    practiceStartedDate,
    activityShowed,
    onActivityEvent,
    showLemonadeOnboarding,
    setShowLemonadeOnboarding,
    hasPreviowsPopups,
    setHasPreviowsPopups,
    questionData,
    getTemplateKey,
    showAbortPracticePopup,
    showIssueReportPopup,
    setShowIssueReportPopup,
    sendIssuesReport,
    showReportSentToast,
    setShowReportSentToast,
    resetPracticeStates,
    hasCheckExerciseAPiResponded,
    getStimulusAudio,
    getTextAudio,
    widthClass,
    setShowDifficultActivityPopup,
    showDifficultActivityPopup,
    isDifficultActivity,
    setIsDifficultActivity,
    showPenAndPaperBadget,
    difficultActivityAnswered,
    pathAssetsSchool,
    checkAnswerThroughApi,
    checkActivityError,
    setCheckActivityError,
    checkActivityRetryValues,
    CHECK_ACTIVITY_ERRORS
  } = usePracticeView({
    practiceBaseData,
    setPracticeBaseData,
    practiceProgressData,
    setPracticeProgressData,
    setPracticeState,
    messageToReactNative,
    playSoundEffect,
    practiceViewRef
  })

  const initialPathSchool = useSelector(
    (state) => state.configuration.pathSchool
  )

  useEffect(() => {
    Analysis.sendSegmentPageEvent(
      Analysis.SEGMENT_PAGE_CATEGORIES.Game,
      Analysis.SEGMENT_EVENTS['Activity Page']
    )
  }, [])

  useEffect(() => {
    if (practiceState === 1) {
      resetPracticeStates()
      setExperienceTotal(parseInt(practiceBaseData?.lessonChallengeExp))
      getPracticeStartExercise()
    }
  }, [practiceState])

  return isBeforeFirstExercise ? (
    <ActivityLoading
      title={translate('app_practice_loading_title')}
      message={translate('app_practice_loading_message')}
    />
  ) : (
    <div
      className={
        'practice-view' + widthClass + (isIphone ? ' practice-view_iPhone' : '')
      }
      ref={practiceViewRef}
    >
      {renderSoundFXController()}
      {practiceBaseData?.statusBarHeight ? (
        <div
          className="margin-for-status-bar"
          style={{
            width: '1px',
            height: practiceBaseData.statusBarHeight + 'px'
          }}
        ></div>
      ) : null}
      <ActivityHeader
        barMax={experienceTotal}
        barCurrent={experienceNormal + experienceExtra}
        messageToReactNative={messageToReactNative}
        isPractice={true}
        setShowAbortPracticePopup={setShowAbortPracticePopup}
        currentExercise={currentExercise}
        previousSeedId={previousSeedId}
        numberExercisesCorrect={numberExercisesCorrect}
        numberExercisesIncorrect={numberExercisesIncorrect}
        practiceStartedDate={practiceStartedDate}
        setShowIssueReportPopup={setShowIssueReportPopup}
        isFromApp={practiceBaseData.isFromApp}
        isDifficultActivity={isDifficultActivity}
        showPenAndPaperBadget={showPenAndPaperBadget}
        difficultActivityAnswered={difficultActivityAnswered}
        practiceBaseData={practiceBaseData}
        pathSchool={initialPathSchool}
      />

      {/* Para "WebView"            -> 'regular-practice':     ejercicios normales y 'scaffolding-practice':     scaffold
          Para "App (react native)" -> 'regular-practice-app': ejercicios normales y 'scaffolding-practice-app': scaffold
       */}
      {activityShowed && (
        <div className="practice-view__exercise regular-practice-app">
          <ActivityBlueberryComponent
            activityType={ACTIVITY_TYPES.PRACTICE}
            activity={activityShowed}
            onActivityEvent={onActivityEvent}
            uiLanguage={getCurrentLangString()}
            isDemoMode={isDevMode}
            activityInformation={{
              exerciseGuid: currentExercise?.guid,
              nodeDepartment: currentExercise?.node_department,
              nodeName: currentExercise?.node_name,
              phase: currentExercise?.phase,
              learningObjectiveName: currentExercise?.learning_objective_name
            }}
            hasCheckExerciseAPiResponded={hasCheckExerciseAPiResponded}
            requiresAudioAssistance={practiceBaseData.requiresAudioAssistance}
            exerciseLanguage={practiceBaseData.language}
            hasPreviowsPopups={hasPreviowsPopups}
            getStimulusAudio={getStimulusAudio}
            getTextAudio={getTextAudio}
            mathRenderEngine="katex"
            pathAssetsSchool={pathAssetsSchool}
          />

          {/* Mostrar como se realiza la actividad (GIF) */}
          {questionData && showLemonadeOnboarding && (
            <ModalLemonadeOnboarding
              open={showLemonadeOnboarding}
              handleClose={(event) => {
                // Ignora cualquier clic que venga desde el interior del modal
                if (event.target !== event.currentTarget) {
                  return
                }
                setShowLemonadeOnboarding(false)
                setHasPreviowsPopups(false)
              }}
              handleCloseBtn={() => {
                setShowLemonadeOnboarding(false)
                setHasPreviowsPopups(false)
              }}
              template={
                questionData?.metadata?.name
                  ? getTemplateKey(
                      questionData.metadata.name,
                      questionData.data
                    )
                  : ''
              }
            />
          )}
        </div>
      )}

      {showAbortPracticePopup && (
        <AbortPractice
          experience={experienceNormal + experienceExtra}
          setShowAbortPracticePopup={setShowAbortPracticePopup}
          messageToReactNative={messageToReactNative}
          practiceStartedDate={practiceStartedDate}
          currentExercise={currentExercise}
          previousSeedId={previousSeedId}
          numberExercisesCorrect={numberExercisesCorrect}
          numberExercisesIncorrect={numberExercisesIncorrect}
          isFromApp={practiceBaseData.isFromApp}
        />
      )}

      {showDifficultActivityPopup && !showLemonadeOnboarding && (
        <InformationPopup
          message={translate('difficult_activity_popup_message')}
          coins={DIFFICULT_ACTIVITY_COINS_REWARD}
          closeAction={() => {
            setIsDifficultActivity(true)
            setShowDifficultActivityPopup(false)
          }}
          buttonText={translate('difficult_activity_popup_button')}
          buttonAction={() => {
            setIsDifficultActivity(true)
            setShowDifficultActivityPopup(false)
          }}
        />
      )}

      {showIssueReportPopup && (
        <IssueReport
          setShowIssueReportPopup={setShowIssueReportPopup}
          sendIssuesReport={sendIssuesReport}
          isFromApp={practiceBaseData.isFromApp}
          practiceBaseData={practiceBaseData}
        />
      )}

      {showReportSentToast !== -1 && (
        <Toast
          closeToast={() => setShowReportSentToast(-1)}
          isOk={showReportSentToast !== 1}
          title={translate(
            showReportSentToast === 0
              ? 'issues_report_feedback_title'
              : 'issues_report_feedback_error_title'
          )}
          message={translate(
            showReportSentToast === 0
              ? 'issues_report_feedback_message'
              : 'issues_report_feedback_error_message'
          )}
        />
      )}

      <ModalSimple
        showModal={checkActivityError}
        onCloseComplete={() => {
          checkAnswerThroughApi(
            checkActivityRetryValues.isUserCorrect,
            checkActivityRetryValues.userResponse,
            checkActivityRetryValues.isScaffold
          )
        }}
        onCloseStart={() => {
          setCheckActivityError(CHECK_ACTIVITY_ERRORS.noError)
          window.history.back();
        }}
        title={translate('popup_error_activity_check_answer_title')}
        message={translate(
          checkActivityError === CHECK_ACTIVITY_ERRORS.timeout
            ? 'popup_error_activity_check_answer_message_try_again'
            : checkActivityError === CHECK_ACTIVITY_ERRORS.timeoutRetries
              ? 'popup_error_activity_check_answer_message_timeout_retries'
              : 'popup_error_activity_check_answer_message_any'
        )}
        buttonText={
          checkActivityError === CHECK_ACTIVITY_ERRORS.timeout
            ? translate('popup_error_activity_check_answer_button_retry')
            : ''
        }
        withCloseButton={true}
        customClass={'modal-practice-network-error'}
      ></ModalSimple>
    </div>
  )
}

export default PracticeView
